import React from "react";
import ReactDOM from "react-dom";
import axios, { AxiosInstance } from "axios";
import { authHeader } from "../authentication/auth-helper";
import { Redirect } from "react-router";
import { authenticationService } from "../authentication/authenticationService";

/*require('es6-promise').polyfill();*/

const baseUrl: string = process.env.REACT_APP_API_BASE_URI || "";

const instance: AxiosInstance = axios.create({
  baseURL: baseUrl,
  headers: {
    "Access-Control-Allow-Origin": "*"
  }
});

instance.interceptors.request.use(
  config => {
    const auth = authHeader();
    config.headers["Authorization"] = auth;
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

instance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error && error.response && error.response.status) {
      if (error.response.status === 401 || error.response.status === 403) {
        authenticationService.logout();
        return <Redirect to={`/login`} />;
      }
    }else{
      console.error(error);
    }
  }
);
export default instance;
