export interface Finding {
  id?: string;
  imageUrl?: string;
  description?: string;
  type?: FindingType;
  findingNumber?: number;
  relativePositionX?: number;
  relativePositionY?: number;
}
export enum FindingType {
  Recommendation,
  Defect
}
export interface Plan {
  id?: string;
  inspectionId?: string;
  propertyId?: string;
  url?: string;
  sourceImageUrl?: string;
  cropX?: number;
  cropY?: number;
  cropWidth?: number;
  cropHeight?: number;
  cropRotation?: number;
  cropScaleX?: number;
  cropScaleY?: number;
}

export interface Inspection {
  id?: string;
  inspectorId?: string;
  propertyId?: string;
  planId?: string;
  contactPersonName?: string;
  contactPersonPhone?: string;
  remarks?: string;
  reminderRemarks?: string;
  internalRemarks?: string;
  reminderState?: ReminderState;
  inspectionTypeRemarks?: string;
  isSystemInstalled?: boolean;
  isFollowUpRequired?: boolean;
  followUpDeadline?: Date;
  isInRenovation?: boolean;
  renovationDeadline?: Date;
  effort?: number;
  startTime?: Date;
  endTime?: Date;
  inspectionType?: InspectionType;
  result?: InspectionResult;
  findings?: Finding[];
  meterValues?: number[];
  status?: InspectionStatus;
  kind?: InspectionKind;
  followupSourceKind?: InspectionKind;
  previousInspection?: string;
  previousInspectionDate?: Date;
  legacyFindings?: string;
  isInspectionLamp?: boolean;
  systemInstaller? : string;
  arresterCount? : number;
  subsidyNumber? : string;
  permitNumber? : string;
  affectedBuildingParts?: string;  
}

export enum ReminderState {
  Inactive = 0,
  Active = 1
}

export interface CalendarItem {
  startDateTime?: Date;
  endDateTime?: Date;
  assuranceNumber: string;
  street: string;
  inspectionId: string;
}

export interface Document {
  id?: string;
  name?: string;
  uri?: string;
  property?: string;
  inspection?: string;
  documentSource?:DocumentSource;
}
export enum InspectionResult {
  FunctionalNoDefect = 0,
  FunctionLimited = 1,
  FunctionDegraded = 2,
  Faulty = 3
}

export enum ConfirmationPersonFunction {
  Unknown = 0,
  Owner = 1,
  Administrator = 2,
  Janitor = 3,
  Tinsmith = 4,
  Electrician = 5
}

export enum InspectionType {
  Optional = 0,
  Mandatory = 1,
  Uncertain = 2
}

export enum InspectionStatus {
  Open = 0,
  Started = 1,
  Completed = 2,
  Cancelled = 3
}

export enum InspectionKind {
  Periodical = 0, //Periodische Kontrolle
  FollowUp = 1, //Nachkontrolle
  Initial = 2
}

export enum InspectorType {
  CommunityInspector = 0,
  RegionSupervisor = 1
}

export enum DocumentSource {
  Unknown = 0,
  InspectionUpload = 1,
  PortalPhotoUpload = 2
}

export interface Address {
  firstname?: string;
  name?: string;
  street?: string;
  streetNumber?: string;
  zipCode?: string;
  city?: string;
  countryCode?: string;
}

export interface AdjacentPropertyAdjacentProperty {
  propertyId: string;
  assuranceNumber: string;
  hasFirewall: boolean;
}

export interface Property {
  id?: string;
  assuranceNumber?: string;
  assuranceNumberGeoPortal?: string;

  bfsNumber?: string;
  usageKind?: string;
  municipality?: string;
  municipalityCode?: string;
  cubature?: number;
  remarks?: string;
  administrator?: Address;
  owner?: Address;
  adjacentProperties?: AdjacentPropertyAdjacentProperty[];
  street?: string;
  streetNumber?: string;
}

export interface User {
  id?: string;
  firstName?: string;
  lastName?: string;
  username?: string;
  password?: string;
  token?: string;
  inspectorType?: InspectorType;
}

export const InspectionKindDisplay: ModelEnum[] = [
  { key: 0, displayValue: "Periodische Kontrolle" },
  { key: 1, displayValue: "Nachkontrolle" },
  { key: 2, displayValue: "Erstabnahme" }
];

export const InspectionStatusDisplay: ModelEnum[] = [
  { key: 0, displayValue: "Offen" },
  { key: 1, displayValue: "In Bearbeitung" },
  { key: 2, displayValue: "Abgeschlossen" }
];

export interface ModelEnum {
  key: number,
  displayValue: string
}