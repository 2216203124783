import * as React from "react";

interface Props {
  assuranceNumberGeoPortal: string;
  bfsNumber: string;
}

const baseUri = "https://www.geoportal.ch/ch/map";

const GeoPortalLink: React.FunctionComponent<Props> = ({
  assuranceNumberGeoPortal,
  bfsNumber
}) => {
  const buildUri = () => {
    if (!bfsNumber || !assuranceNumberGeoPortal) return "";
    const url = `${baseUri}/40?scale=500&highlight=1&topic=assuranceNo&bfsnr=${bfsNumber}&assuranceNo=${assuranceNumberGeoPortal}`;

    return url;
  };

  const invalidParameters = !bfsNumber || !assuranceNumberGeoPortal;

  return invalidParameters ? null : (
    <a href={buildUri()} target="_blank">
      GeoPortal
    </a>
  );
};

export default GeoPortalLink;
