import { Button } from 'antd';
import 'antd/dist/antd.css';
import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import { MarginFormItem, tailFormItemLayout } from '../../commonFormStyling';


export interface PortalConfirmationPhotosProps {
    photos?: string[];
}
export const PortalConfirmationPhotos = (props: PortalConfirmationPhotosProps) => {

    const { photos } = props;

    const [isLighboxOpen, setIsLighboxOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);

    const hasPhotos = photos && photos.length > 0;

    return (
        <div>
            <MarginFormItem style={{ marginBottom: 0 }}
                {...tailFormItemLayout}
                label="Bereitgestellte Fotos">

                {hasPhotos && (<Button style={{ marginLeft: 20 }} onClick={() => setIsLighboxOpen(true)}>Anzeigen</Button>)}
                {!hasPhotos && (<div style={{ marginLeft: 20 }}>keine</div>)}

            </MarginFormItem>

            {isLighboxOpen && (<Lightbox
                mainSrc={photos[photoIndex]}
                nextSrc={photos[(photoIndex + 1) % photos.length]}
                prevSrc={photos[(photoIndex + photos.length - 1) % photos.length]}
                onCloseRequest={() => setIsLighboxOpen(false)}
                onMovePrevRequest={() => setPhotoIndex((photoIndex + photos.length - 1) % photos.length)}
                onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % photos.length)} />
            )}
        </div>
    );
}