import * as React from "react";
import { Input, Form, Row } from "antd";
import { Address } from "../../../../../../models";

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 14 }
};

interface AdministratorProps {
  title: string;
  formFieldIdPrefix: string
  address?: Address;
  form: any;
}

const AddressFormData = (props: AdministratorProps) => {
  const { getFieldDecorator } = props.form;

  const {
    firstname = "",
    name = "",
    city = "",
    street = "",
    streetNumber = "",
    zipCode = ""
  } = props.address || {};

  return (
    <>
      <Row>
        <h3>{props.title}</h3>
      </Row>
      <Row style={{ marginTop: "10px" }}>
        <Form.Item label="Vorname" {...formItemLayout}>
          {getFieldDecorator(`${props.formFieldIdPrefix}-firstname`, {
            initialValue: firstname,
            rules:[ {max:45, message:"Der Wert darf nicht länger als 45 Zeichen sein."}]
          })(<Input placeholder="Vorname" />)}
        </Form.Item>

        <Form.Item label="Name" {...formItemLayout}>
          {getFieldDecorator(`${props.formFieldIdPrefix}-name`, {
            initialValue: name,
            rules: [{ required: true, message: "Bitte geben Sie einen Namen ein." },
            {max:45, message:"Der Wert darf nicht länger als 45 Zeichen sein."}]
          })(<Input placeholder="Name" />)}
        </Form.Item>

        <Form.Item label="Strasse" {...formItemLayout}>
          {getFieldDecorator(`${props.formFieldIdPrefix}-street`, {
            initialValue: street,
            rules: [{ required: true, message: "Bitte geben Sie eine Strasse ein." },
             {max:100, message:"Der Wert darf nicht länger als 100 Zeichen sein."}]
          })(<Input placeholder="Strasse" />)}
        </Form.Item>

        <Form.Item label="Hausnummer" {...formItemLayout}>
          {getFieldDecorator(`${props.formFieldIdPrefix}-streetNr`, {
            initialValue: streetNumber,
            rules:[ {max:9, message:"Der Wert darf nicht länger als 9 Zeichen sein."}]
            
          })(<Input placeholder="Hausnummer" />)}
        </Form.Item>

        <Form.Item label="Postleitzahl" {...formItemLayout}>
          {getFieldDecorator(`${props.formFieldIdPrefix}-zipCode`, {
            initialValue: zipCode,
            rules: [{ required: true, message: "Bitte geben Sie eine Postleitzahl ein." }, 
            {max:5, message:"Der Wert darf nicht länger als 5 Zeichen sein."}]
          })(<Input placeholder="Postleitzahl" />)}
        </Form.Item>
        <Form.Item label="Ort" {...formItemLayout}>
          {getFieldDecorator(`${props.formFieldIdPrefix}-city`, {
            initialValue: city,
            rules: [{ required: true, message: "Bitte geben Sie einen Ort ein." },
            {max:100, message:"Der Wert darf nicht länger als 100 Zeichen sein."}]
          })(<Input placeholder="Ort" />)}
        </Form.Item>
      </Row>
    </>
  );
};

export default AddressFormData;
