export const Municipalities: { code: number, text: string }[] = [
    { code: 58, text: "Alt St. Johann" },
    { code: 24, text: "Altstätten" },
    { code: 42, text: "Amden" },
    { code: 89, text: "Andwil" },
    { code: 17, text: "Au" },
    { code: 36, text: "Bad Ragaz" },
    { code: 19, text: "Balgach" },
    { code: 45, text: "Benken" },
    { code: 8, text: "Berg" },
    { code: 18, text: "Berneck" },
    { code: 83, text: "Bronschhofen" },
    { code: 67, text: "Brunnadern" },
    { code: 31, text: "Buchs" },
    { code: 71, text: "Bütschwil" },
    { code: 81, text: "Degersheim" },
    { code: 20, text: "Diepoldsau" },
    { code: 62, text: "Ebnat-Kappel" },
    { code: 11, text: "Eggersriet" },
    { code: 25, text: "Eichberg" },
    { code: 49, text: "Ernetschwil" },
    { code: 54, text: "Eschenbach" },
    { code: 80, text: "Flawil" },
    { code: 39, text: "Flums" },
    { code: 91, text: "Gaiserwald" },
    { code: 29, text: "Gams" },
    { code: 76, text: "Ganterschwil" },
    { code: 6, text: "Goldach" },
    { code: 55, text: "Goldingen" },
    { code: 48, text: "Gommiswald" },
    { code: 88, text: "Gossau" },
    { code: 30, text: "Grabs" },
    { code: 3, text: "Häggenschwil" },
    { code: 68, text: "Hemberg" },
    { code: 53, text: "Jona" },
    { code: 77, text: "Jonschwil" },
    { code: 46, text: "Kaltbrunn" },
    { code: 74, text: "Kirchberg" },
    { code: 70, text: "Krinau" },
    { code: 61, text: "Krummenau" },
    { code: 65, text: "Lichtensteig" },
    { code: 72, text: "Lütisburg" },
    { code: 23, text: "Marbach" },
    { code: 38, text: "Mels" },
    { code: 75, text: "Mogelsberg" },
    { code: 5, text: "Mörschwil" },
    { code: 73, text: "Mosnang" },
    { code: 4, text: "Muolen" },
    { code: 60, text: "Nesslau" },
    { code: 86, text: "Niederbüren" },
    { code: 87, text: "Niederhelfenschwil" },
    { code: 85, text: "Oberbüren" },
    { code: 66, text: "Oberhelfenschwil" },
    { code: 26, text: "Oberriet" },
    { code: 78, text: "Oberuzwil" },
    { code: 37, text: "Pfäfers" },
    { code: 41, text: "Quarten" },
    { code: 52, text: "Rapperswil" },
    { code: 22, text: "Rebstein" },
    { code: 15, text: "Rheineck" },
    { code: 47, text: "Rieden" },
    { code: 13, text: "Rorschach" },
    { code: 12, text: "Rorschacherberg" },
    { code: 27, text: "Rüthi" },
    { code: 34, text: "Sargans" },
    { code: 44, text: "Schänis" },
    { code: 51, text: "Schmerikon" },
    { code: 28, text: "Sennwald" },
    { code: 32, text: "Sevelen" },
    { code: 16, text: "St. Margrethen" },
    { code: 69, text: "St. Peterzell" },
    { code: 97, text: "St.Gallen Centrum" },
    { code: 98, text: "St.Gallen Ost" },
    { code: 99, text: "St.Gallen West" },
    { code: 56, text: "St.Gallenkappel" },
    { code: 59, text: "Stein" },
    { code: 7, text: "Steinach" },
    { code: 14, text: "Thal" },
    { code: 9, text: "Tübach" },
    { code: 10, text: "Untereggen" },
    { code: 50, text: "Uznach" },
    { code: 79, text: "Uzwil" },
    { code: 35, text: "Vilters-Wangs" },
    { code: 90, text: "Waldkirch" },
    { code: 40, text: "Walenstadt" },
    { code: 33, text: "Wartau" },
    { code: 64, text: "Wattwil" },
    { code: 43, text: "Weesen" },
    { code: 21, text: "Widnau" },
    { code: 82, text: "Wil" },
    { code: 57, text: "Wildhaus" },
    { code: 2, text: "Wittenbach" },
    { code: 84, text: "Zuzwil" }
]