import React, { useState, useRef, useContext } from "react";
import { Form, DatePicker, Input, Checkbox, Col, Button, Row } from "antd";
import { FormComponentProps } from "antd/lib/form/Form";
import {
  Inspection,
  Property,
  InspectionKind
} from "../../../../../../../../models";
import {
  MarginFormItem,
  tailFormItemLayout,
  itemWithoutLabelLayout
} from "../../commonFormStyling";
import { isNullOrUndefined } from "util";
import InspectionContext from "../../../../../../../../context/inspection-context";
import moment from "moment";
import PreviousInspectionLink from "../../../../../../../shared/PreviousInspectionLink";
import UploadZone from "../../InspectionStep/UploadZone";
import { PortalConfirmationDetails } from "./PortalConfirmationDetails";


const { TextArea } = Input;

moment.locale("de-CH");

interface Props extends FormComponentProps {
  inspection: Inspection;
  dateRange: moment.Moment[];
  property: Property;
  removeInspectionDate: () => void;
  updateInspection: (inspection: Inspection, property?: Property) => void;
}

const InspectionPreparationForm = (props: Props) => {
  const { getFieldDecorator, getFieldValue } = props.form;
  const [isInRenovationState, setInRenovation] = useState();
  const [isSystemInstalledState, setIsSytemInstalled] = useState();

  const {
    contactPersonName,
    contactPersonPhone,
    isSystemInstalled,
    renovationDeadline,
    isInRenovation,
    kind,
    previousInspection,
    previousInspectionDate,
    legacyFindings,
    internalRemarks
  } = props.inspection;

  const {
    submitFormRef,
    updateInspectionHandler,
    onChangeSystemInstalled,
    onChangeObjectIsInRenovation,
    inspection
  } = useContext(InspectionContext);

  const ref = useRef({
    submit: nextStep => {
      submitForm(nextStep);
    }
  });

  const onChangeIsSystemInstalled = (e: any) => {
    setIsSytemInstalled(!e.target.checked);
    onChangeSystemInstalled(e);
  };

  const onChangeInRenovation = (e: any) => {
    setInRenovation(e.target.checked);
    onChangeObjectIsInRenovation(e);
  };

  const onInspectionDateChange = (e: any) => {
    inspection.startTime = null;
  };

  submitFormRef(ref);

  const submitForm = (nextStep?: number) => {
    props.form.validateFields((errors, values) => {
      if (!errors) {
        const newInspection: Inspection = {
          contactPersonName: getFieldValue("input-contactPersonName"),
          contactPersonPhone: getFieldValue("input-contactPersonPhone"),
          isInRenovation: getFieldValue("checkbox-object-in-renovation"),
          renovationDeadline: getFieldValue("date-picker-deadline"),
          isSystemInstalled: !getFieldValue("checkbox-no-object-exists"),
          internalRemarks: getFieldValue("textarea-internalremarks")
        };

        updateInspectionHandler(newInspection, {}, nextStep);
      }
    });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    submitForm(null);
  };

  const renovationDate = renovationDeadline ? moment(renovationDeadline) : null;

  const isInRenov =
    isInRenovationState === undefined ? isInRenovation : isInRenovationState;

  const isSytemInstalledDisabled =
    isInRenovationState === undefined ? isInRenovation : isInRenovationState;

  const isInRenovationDisabled =
    isSystemInstalledState === undefined
      ? isSystemInstalled
      : isSystemInstalledState;

  const renovationDeadlineControl = isInRenov ? (
    <MarginFormItem label="Frist Umbau">
      {getFieldDecorator("date-picker-deadline", {
        initialValue: renovationDate,
        rules: [
          {
            required: true,
            message: "Bitte geben Sie eine Frist zum Umbau an."
          }
        ]
      })(<DatePicker format="DD.MM.YYYY" />)}
    </MarginFormItem>
  ) : null;

  const propertyIsNull = isNullOrUndefined(props.property);

  return (
    <Form
      id="preperationForm"
      {...tailFormItemLayout}
      labelAlign="left"
      onSubmit={handleSubmit}
    >
      <Row>
        <Col>
          {previousInspection && (
            <MarginFormItem label="Letzte Kontrolle">
              <PreviousInspectionLink
                inspectionId={previousInspection}
                inspectionDate={previousInspectionDate}
              />
            </MarginFormItem>
          )}
          <MarginFormItem label="Kontrolldatum">
            {getFieldDecorator("date-picker-inspectiondate", {
              initialValue: props.dateRange[0].isValid()
                ? props.dateRange[0]
                : null
            })(
              <DatePicker
                disabled
                format="DD.MM.YYYY"
                onChange={onInspectionDateChange}
              />
            )}{" "}
            <Button onClick={props.removeInspectionDate} shape="circle" icon="delete"></Button>
          </MarginFormItem>
          <MarginFormItem label="Kontaktperson">
            {getFieldDecorator("input-contactPersonName", {
              initialValue: contactPersonName
            })(<Input />)}
          </MarginFormItem>
          <MarginFormItem label="Telefonnummer" {...tailFormItemLayout}>
            {getFieldDecorator("input-contactPersonPhone", {
              initialValue: contactPersonPhone
            })(<Input style={{ width: "100%" }} />)}
          </MarginFormItem>

          {kind === InspectionKind.Periodical && (
            <MarginFormItem {...itemWithoutLabelLayout}>
              {getFieldDecorator("checkbox-no-object-exists", {
                valuePropName: "checked",
                initialValue: !isSystemInstalled
              })(
                <Checkbox
                  onChange={onChangeIsSystemInstalled}
                  disabled={isSytemInstalledDisabled}
                >
                  Keine Anlage vorhanden
                </Checkbox>
              )}
            </MarginFormItem>
          )}

          {kind === InspectionKind.Periodical && (
            <MarginFormItem {...itemWithoutLabelLayout}>
              {getFieldDecorator("checkbox-object-in-renovation", {
                valuePropName: "checked",
                initialValue: isInRenovation
              })(
                <Checkbox
                  onChange={onChangeInRenovation}
                  disabled={!isInRenovationDisabled}
                >
                  Objekt in Umbau
                </Checkbox>
              )}
            </MarginFormItem>
          )}

          {renovationDeadlineControl}

          <MarginFormItem {...itemWithoutLabelLayout}   >
            {getFieldDecorator("textarea-internalremarks", {
              initialValue: internalRemarks
            })(
              <TextArea
                disabled={propertyIsNull}
                rows={4}
                placeholder="Interne Bemerkungen zum Objekt"
              />
            )}
          </MarginFormItem>

          {legacyFindings && (
            <MarginFormItem
              {...tailFormItemLayout}
              label="Mängel aus altem System"
            >
              {getFieldDecorator("textarea-legacyfindings", {
                initialValue: legacyFindings
              })(<TextArea disabled={propertyIsNull} rows={4} />)}
            </MarginFormItem>
          )}
        </Col>
      </Row>

      <Row style={{ marginTop: "10px" }}>
        <Col>
          <Form.Item {...itemWithoutLabelLayout}>
            <Button
              icon="save"
              htmlType="submit"
              form="preperationForm"
              type="primary"
            >
              Daten Speichern
            </Button>
          </Form.Item>
        </Col>
      </Row>
      {

      }
      {previousInspection && (<Row>
        <Col span={22}>
          <PortalConfirmationDetails inspectionId={previousInspection} />
        </Col>
        <Col span={1}></Col>
      </Row>)}

      {props.property &&props.property.id &&
        (<Row style={{ marginTop: 10 }}>
          <Col>
            <MarginFormItem label="Dokumente">
              <UploadZone inspection={props.inspection} propertyId={props.property.id} />
          </MarginFormItem>
          </Col>
          <Col span={1}></Col>
        </Row>
        )}
    </Form>
  );
};

export default Form.create<Props>()(InspectionPreparationForm);