import rawaxios from "axios";
import axios from "../environment/axios";

import {
  Inspection,
  Property,
  User,
  Finding,
  FindingType,
  CalendarItem,
  Document,
} from "../models";
import moment from "moment";
import dayjs from "dayjs";

export async function getInspectionById(id: string): Promise<Inspection> {
  const result = await axios.get<Inspection>(`/inspections/${id}`);
  if (!result || !result.data) return null;
  return result.data;
}

export async function getDocumentsByInspection(
  inspectionid: string
): Promise<Document[]> {
  const result = await axios.get<Document[]>(
    `/inspections/${inspectionid}/documents`
  );
  if (!result || !result.data) return null;
  return result.data;
}

export async function deleteDocument(documentId: string): Promise<void> {
  await axios.delete(`/documents/${documentId}`);
}

export async function getCalendarByInspector(
  inspectorsId: string,
  startDate: Date,
  endDate: Date
): Promise<CalendarItem[]> {
  var start = moment(startDate).toISOString();
  var end = moment(endDate).toISOString();
  const result = await axios.get<CalendarItem[]>(
    `/calendar/${inspectorsId}/?fromDateTime=${start}&toDateTime=${end}`
  );
  if (!result || !result.data) return null;
  return result.data;
}

export async function updateInspection(inspection: Inspection): Promise<void> {
  await axios.put<Inspection>(`/inspections/${inspection.id}`, inspection);
}

export async function createInspection(
  inspection: Inspection
): Promise<Inspection> {
  const result = await axios.post<Inspection>(`/inspections`, inspection);
  if (!result || !result.data) return null;
  return result.data;
}

export async function deleteInspection(inspectionId: string): Promise<void> {
  await axios.delete(`/inspections/${inspectionId}`);
}

export async function removeInspectionFromInbox(
  inspectionId: string
): Promise<void> {
  await axios.get(`/inspections/${inspectionId}/removeFromInbox`);
}

export async function getInspectionFindings(
  inspectionId: string
): Promise<Finding[]> {
  const result = await axios.get<Finding[]>(
    `/inspections/${inspectionId}/findings`
  );
  if (!result || !result.data) return null;
  return result.data;
  //return await getMockFindings();
}
export async function getPropertyById(id: string): Promise<Property> {
  const result = await axios.get<Property>(`/properties/${id}`);
  if (!result || !result.data) return null;
  return result.data;
}

export async function updateProperty(property: Property): Promise<void> {
  await axios.put<Property>(`/properties/${property.id}`, property);
}

export async function deleteProperty(propertyId: string): Promise<void> {
  await axios.delete(`/properties/${propertyId}`);
}

export async function createProperty(property: Property): Promise<Property> {
  const result = await axios.post<Property>(`/properties`, property);
  if (!result || !result.data) return null;
  return result.data;
}

export async function authenticate(
  username: string,
  password: string
): Promise<User> {
  const user: User = {
    username: username,
    password: password,
  };
  const result = await axios.post<User>(`/users/authenticate/`, user);
  return result.data;
}

export async function changePassword(password: string): Promise<void> {
  await axios.post<string>(`/users/changePassword/`, password);
}

export async function uploadDocument(
  signedUrl: string,
  file: File
): Promise<any> {
  const result = await rawaxios.put(signedUrl, file, {
    headers: {
      "x-ms-blob-type": "BlockBlob",
      "x-ms-date": dayjs().toISOString(),
      "Cache-Control": "no-cache",
      "Content-Type": file.type,
    },
  });
  return result;
}

export async function createDocument(document: Document) {
  const result = await axios.post<Document>(`/documents`, document);
  if (!result || !result.data) return null;

  if (result.status !== 200) {
    throw new Error("Dokumenterstellung fehlgeschlagen: " + result.statusText);
  }

  return result.data;
}

export async function getPortalConfirmationPhotos(
  inspectionId: string
): Promise<Document[]> {
  const result = await axios.get<Document[]>(
    `/documents/confirmationPhotos/${inspectionId}`
  );
  if (!result || !result.data) return null;
  return result.data;
}

export async function getCancelledInspections(
  inspectorId: string
): Promise<Inspection[]> {
  const result = await axios.get<Inspection[]>(
    `/inspections/cancelled/${inspectorId}`
  );
  if (!result || !result.data) return null;

  return result.data;
}

export async function putAcceptCancellation(
  inspectorId: string
): Promise<Inspection[]> {
  const result = await axios.put<Inspection[]>(
    `/inspections/acceptCancellations/${inspectorId}`
  );
  if (!result || !result.data) return null;

  return result.data;
}

export async function getSystemInstallers(inspectorId): Promise<string[]> {
  const result = await axios.get<string[]>(
    `/inspections/systemInstallers/${inspectorId}`
  );
  if (!result || !result.data) return null;
  return result.data;
}

export async function getMockInspections(
  inspectorId: string
): Promise<Inspection[]> {
  const inspections: Inspection[] = [
    {
      propertyId: "1",
    },
    {
      propertyId: "2",
    },
    {
      propertyId: "3",
    },
  ];
  return new Promise<Finding[]>((resolve, reject) => {
    resolve(inspections);
  });
}

export async function getMockProperty(id: string): Promise<Property> {
  const properties: Property[] = [
    {
      assuranceNumber: "12345.11",
      usageKind: "Stall",
      street: "Marktgasse 5",
      municipality: "Wittenbach",
      id: "1",
    },
    {
      assuranceNumber: "12345.11",
      usageKind: "Stall",
      street: "Marktgasse 5",
      municipality: "Wittenbach",
      id: "2",
    },
    {
      assuranceNumber: "12345.11",
      usageKind: "Stall",
      street: "Marktgasse 5",
      municipality: "Wittenbach",
      id: "3",
    },
  ];

  return new Promise<Property>((resolve, reject) => {
    resolve(properties.filter((x) => x.id === id)[0]);
  });
}

export async function getMockFindings(): Promise<Finding[]> {
  const findings: Finding[] = [
    {
      description:
        "Description Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse at elit sed enim pretium venenatis. Nulla quis venenatis turpis. Aenean sodales scelerisque sagittis. Interdum et malesuada fames ac ante ipsum primis in faucibus. Donec finibus egestas mi, sed consectetur massa faucibus id ",
      findingNumber: 12345,
      id: "1243234234",
      imageUrl: "https://via.placeholder.com/150",
      type: FindingType.Defect,
    },
    {
      description:
        "Description Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse at elit sed enim pretium venenatis. Nulla quis venenatis turpis. Aenean sodales scelerisque sagittis. Interdum et malesuada fames ac ante ipsum primis in faucibus. Donec finibus egestas mi, sed consectetur massa faucibus id ",
      findingNumber: 1234511,
      id: "12432344",
      imageUrl: "https://via.placeholder.com/150",
      type: FindingType.Recommendation,
    },
    {
      description:
        "Description Lorem ipsum dolor sit amet, consectetur adipiscing elit. Interdum et malesuada fames ac ante ipsum primis in faucibus. Donec finibus egestas mi, sed consectetur massa faucibus id ",
      findingNumber: 1234523,
      id: "124323234",
      imageUrl: "https://via.placeholder.com/150",
      type: FindingType.Defect,
    },
    {
      description:
        "Description Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      findingNumber: 12343,
      id: "124324234",
      imageUrl: "https://via.placeholder.com/150",
      type: FindingType.Recommendation,
    },
  ];

  return new Promise<Finding[]>((resolve, reject) => {
    resolve(findings);
  });
}
