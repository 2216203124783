import React, { useState } from "react";
import {
  Form,
  Icon,
  Input,
  Button,
  Checkbox,
  Row,
  Col,
  Typography
} from "antd";
import { authenticationService } from "../../../../authentication/authenticationService";

const LoginScreen = (props: any) => {
  const [hasLoginFailed, setHasLoginFailed] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        authenticationService
          .login(values.username, values.password)
          .then(isLoginSuccessfull => {
            if(isLoginSuccessfull){
              setHasLoginFailed(false);
              props.history.push(`/kontrollen`);              
            }else{
              setHasLoginFailed(true);
            }
            
          })
          .catch(err => {
            console.error(err);
            setHasLoginFailed(true);
          });
      }
    });
  };

  const { getFieldDecorator } = props.form;
  return (
    <>
      <Form onSubmit={handleSubmit} style={{ padding: "30px" }}>
        <Col>
          <Row style={{ marginTop: "10px" }}>
            <Col lg={8} sm={14}>
              <h2>Login</h2>
            </Col>
          </Row>
          <Row>
            <Col lg={8} sm={14}>
              <Form.Item>
                {getFieldDecorator("username", {
                  rules: [
                    { required: true, message: "Bitte geben Sie Ihren Benutzernamen ein." }
                  ]
                })(
                  <Input
                    prefix={
                      <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    placeholder="Benutzername"
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col lg={8} sm={14}>
              <Form.Item>
                {getFieldDecorator("password", {
                  rules: [
                    { required: true, message: "Bitte geben Sie ihr Passwort ein." }
                  ]
                })(
                  <Input
                    prefix={
                      <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    type="password"
                    placeholder="Passwort"
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col lg={2} sm={14}>
              <Button block type="primary" htmlType="submit">
                Log in
              </Button>
            </Col>
          </Row>
          {hasLoginFailed ?
          <Row style={{ marginTop: "10px" }}>
            <Col>
              <Typography.Text type="danger">
                Login fehlgeschlagen
              </Typography.Text>
            </Col>
          </Row> : null}
        </Col>
      </Form>
    </>
  );
};
const WrappedLoginScreen = Form.create({ name: "loginscreen" })(LoginScreen);

export default WrappedLoginScreen;
