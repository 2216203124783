import React from 'react';
import { Route, Switch } from 'react-router-dom';
import InboxScreen from "../components/routes/private/Inbox";
import InspectionList from "../components/routes/private/InspectionList";
import UserSettings from "../components/routes/private/UserSettings";
import InspectionsDetail from "../components/routes/private/InspectionsDetail";
import LoginScreen from '../components/routes/public/Login';
import { PrivateRoute } from '../components/shared/PrivateRoute';

const routes = () => (    
    <Switch>        
        <Route path="/login" component={LoginScreen} /> 
        <PrivateRoute path="/inbox" component={InboxScreen} />
        <PrivateRoute path="/kontrollen/:inspectionId" component={InspectionsDetail} />
        <PrivateRoute path="/(kontrollen|)" component={InspectionList} />                
        <PrivateRoute path="/settings" component={UserSettings} />
    </Switch>
  );

export default routes;