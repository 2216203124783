import React from "react";
import { Menu, Icon } from "antd";
import { ClickParam } from "antd/lib/menu";
import { NavLink, withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router-dom";

import logo from "../../../../assets/gvsg_logo.png";
import { authenticationService } from "../../../../authentication/authenticationService";
import "./index.css";

interface ChildComponentProps extends RouteComponentProps<any> { }

class TopNavigation extends React.Component<ChildComponentProps> {
  state = {
    current: "kontrollen"
  };

  componentDidMount() {
    
    // this.setState({ headerClass: headerClass });
  }

  constructor(props) {
    super(props);
  }



  handleClick = (e: ClickParam) => {
    if (e.key === "logout") {
      authenticationService.logout();
      this.props.history.push("/login");
    } else {
      this.setState({
        current: e.key
      });
    }
  };

  render() {

    const headerClass = process.env.REACT_APP_RUNTIME_ENVIRONMENT === "Development" ? "devHeader" :
    process.env.REACT_APP_RUNTIME_ENVIRONMENT === "Test" ? "testHeader" : undefined;

    return (
      <div>
        <Menu
          onClick={this.handleClick}
          selectedKeys={[this.state.current]}
          mode="horizontal"
          theme="light"
          className={headerClass}
        >
          <Menu.Item>
            <img src={logo} />
          </Menu.Item>
          <Menu.Item key="inbox">
            <NavLink to="/inbox">Inbox</NavLink>
          </Menu.Item>
          <Menu.Item key="inspectionList">
            <NavLink to="/kontrollen">Kontrollen</NavLink>
          </Menu.Item>
          <Menu.Item key="logout" style={{ float: "right" }}>
            <Icon type="logout" />
          </Menu.Item>
          <Menu.Item key="settings" style={{ float: "right" }}>
            <NavLink to="settings">
              <Icon type="setting" />
            </NavLink>
          </Menu.Item>
        </Menu>
      </div>
    );
  }
}
export default withRouter(TopNavigation);
