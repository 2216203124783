import styled from "styled-components";
import { Form } from "antd";

export const MarginFormItem = styled(Form.Item)`
  margin-bottom: 3px;
`;

export const tailFormItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 18, offset: 0 },
    lg: { span: 20, offset: 0 },
    xl: { span: 5, offset: 0 }
  },
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 8, offset: 0 },
    lg: { span: 18, offset: 0 },
    xl: { span: 12, offset: 1 }
  }
};

export const itemWithoutLabelLayout = {
  labelCol: {
    sm: { span: 24 },
    lg: { span: 12 },
    xl: { span: 5 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24, offset: 0 },
    xl: { span: 12, offset: 6 }
  }
};

export const smallFields = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8, offset: 0 },
      lg: { span: 8, offset: 0 },
      xl: { span: 6, offset: 0 }
    },
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 8, offset: 0 },
      lg: { span: 4, offset: 0 },
      xl: { span: 4, offset: 0 }
    }
  };