import * as React from "react";
import styled from "styled-components";
import { Steps, Row, Col, Button } from "antd";

import PreparationStep from "./Steps/PreparationStep";
import { MarginComponent } from "../../../../shared/base-styles";
import CompletedStep from "./Steps/CompletedStep";
import InspectionStep from "./Steps/InspectionStep";
import { Inspection, Property } from "../../../../../models";
import InspectionContext from "../../../../../context/inspection-context";

const Step = Steps.Step;

const AssistantBorder = styled.div`
  min-height: 400px;
  border: 1px solid #e8e8e8;
  padding: 10px;
`;
interface Props {
  currentStep: number;
  inspection: Inspection;
  property: Property;
  updateInspection: (inspection: Inspection, property?: Property) => void;
  isNotSystemInstalled: boolean;
  objectIsInRenovation: boolean;
  stepFormRef?: any;
}

const InspectionAssistant = (props: Props) => {
  const steps = [
    <Step key="0">
      <PreparationStep
        inspection={props.inspection}
        property={props.property}
        updateInspection={props.updateInspection}
        stepFormRef={props.stepFormRef}
      />
    </Step>,
    <Step key="1">
      <InspectionContext.Consumer>
        {() => (
          <InspectionStep
            inspection={props.inspection}
            property={props.property}
            updateInspection={props.updateInspection}
            ref={props.stepFormRef}
          />
        )}
      </InspectionContext.Consumer>
    </Step>,
    <Step key="2">
      <CompletedStep inspection={props.inspection} property={props.property} />
    </Step>
  ];

  return (
    <AssistantBorder>
      <Row>
        <Col>
          <Steps current={props.currentStep}>
            <Step title="Vorbereitung" />
            {!props.objectIsInRenovation && !props.isNotSystemInstalled ? <Step title="Durchführung" /> : null}
            <Step title="Abgeschlossen" />
          </Steps>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <MarginComponent top="20px" className="steps-content">
            {steps[props.currentStep].props.children}
          </MarginComponent>
        </Col>
      </Row>
    </AssistantBorder>
  );
};

export default InspectionAssistant;
