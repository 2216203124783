import * as React from "react";
import moment from "moment";
import { Alert, Row, Col } from "antd";
import DateIsInFutureNotification from "./DateInFuture/dateIsInFuture";
import ReminderNotification from "./ReminderNotification/reminderNotification";

interface Props {
  date: Date;
  isReminderFlagSet: boolean;
  canComplete: boolean;
  assistantStep: number;
}

const Notifications = (props: Props) => {
  const isInFuture = (): boolean => {
    return !moment().isAfter(props.date);
  };

  return (
    <>
      {isInFuture() && props.canComplete ? (
        <Row style={{ marginTop: "10px" }} type="flex" justify="center">
          <Col span={22}>
            <DateIsInFutureNotification />
          </Col>
        </Row>
      ) : null}
      {props.isReminderFlagSet ? (
        <Row style={{ marginTop: "10px" }} type="flex" justify="center">
          <Col span={22}>
            <ReminderNotification />
          </Col>
        </Row>
      ) : null}
    </>
  );
};

export default Notifications;
