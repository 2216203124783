import { authenticationService } from "./authenticationService";
import { User } from "../models";

export function authHeader() {
    // return authorization header with jwt token
    const { currentUserValue } = authenticationService;

    if (currentUserValue && currentUserValue.token) {
        return `Bearer ${currentUserValue.token}`;
    } else {
        return "";
    }
}

export function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if ([401, 403].indexOf(response.status) !== -1) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                authenticationService.logout();
                window.location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}