import * as React from "react";
import { Row, Col } from "antd";
import { getInspectionFindings } from "../../../../../../../../endpoints";
import { useEffect } from "react";
import { Finding } from "../../../../../../../../models";
import FindingDisplay from "./FindingDisplay";

interface Props{
    inspectionId: string
}

const Findings = (props: Props) => {

    const [findings, setFindings] = React.useState<Finding[]>([]);

    useEffect(() => {
        const {inspectionId} = props;
        
        getInspectionFindings(inspectionId).then(findings => {
            setFindings(findings);            
        })
    }, [props.inspectionId]);


    return(<>
        <Row style={{borderBottom: '1px solid #e8e8e8'}}>
            <h3>Mängel</h3>
        </Row>
        {/* <Row style={{marginTop: '10px'}}>
            <Col lg={24}>
                <img src="https://via.placeholder.com/700x300?text=Karte" />
            </Col>
        </Row> */}
        <Row>
            <Col>
                {findings.map(finding => <FindingDisplay key={finding.id} finding={finding} />)}
            </Col>
        </Row>
    
    </>)
}

export default Findings;