import * as React from "react";
import {
  Form,
  Row,
  Col,
  Input,
  Checkbox,
  DatePicker,
  Select,
  Button,
  Radio
} from "antd";
import { FormComponentProps } from "antd/lib/form";
import Findings from "./Findings";
import {
  InspectionResult,
  Property,
  Inspection,
  InspectionType,
  InspectionKind,
  InspectionStatus,
  ReminderState
} from "../../../../../../../models";
import TextArea from "antd/lib/input/TextArea";
import UploadZone from "./UploadZone";
import {
  itemWithoutLabelLayout,
  MarginFormItem,
  tailFormItemLayout,
  smallFields
} from "../commonFormStyling";
import { isNullOrUndefined } from "util";
import InspectionContext from "../../../../../../../context/inspection-context";

let moment = require("moment");
moment.locale("de-CH");

const { RangePicker } = DatePicker;
const { Option } = Select;

interface Props extends FormComponentProps {
  inspection: Inspection;
  property: Property;
  updateInspection: (inspection: Inspection, property?: Property) => void;
}

const InspectionStep = (props: Props) => {
  const {
    startTime,
    effort,
    contactPersonName,
    kind,
    contactPersonPhone,
    id,
    endTime,
    reminderRemarks,
    isFollowUpRequired,
    followUpDeadline,
    inspectionTypeRemarks,
    inspectionType,
    remarks,
    followupSourceKind,
    result,
    reminderState
  } = props.inspection;

  let systemInstaller = "";
  let propertyRemarks = "";
  let arresterCount = 0;

  const { getFieldValue, getFieldDecorator, getFieldsError } = props.form;

  const { submitFormRef, updateInspectionHandler } = React.useContext(
    InspectionContext
  );

  const ref = React.useRef({
    submit: nextStep => {
      submitForm(nextStep);
    }
  });

  submitFormRef(ref);

  const submitForm = (nextStep?: number) => {
    props.form.validateFields((errors, values) => {
      if (!errors) {
        const updateInspection: Inspection = {
          result: getFieldValue("select-F-result"),
          isFollowUpRequired: getFieldValue("checkbox-isFollowUpRequired"),
          effort: getFieldValue("input-effort"),
          followUpDeadline: getFieldValue("date-picker-followUpDeadline"),
          reminderRemarks: getFieldValue("textarea-reminderremarks"),
          remarks: getFieldValue("textarea-inspection-remarks"),
          status: InspectionStatus.Started,
          inspectionType: getFieldValue("radio-inspectionType"),
          inspectionTypeRemarks: getFieldValue(
            "textarea-inspection-inspectionTypeRemarks"
          ),
          systemInstaller:getFieldValue("input-systemInstaller"),
          arresterCount: getFieldValue("input-arresterCount"),
        };
        const newProperty: Property = {
          remarks: getFieldValue("textarea-internalremarks")         
        };
        updateInspectionHandler(updateInspection, newProperty, nextStep);
      }
    });
  };

  const hasErrors = fieldsError => {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
  };

  const disabledFutureDate = current => {
    // Can not select days before today and today
    return current < moment().endOf("day");
  };

  if (!isNullOrUndefined(props.property)) {
    systemInstaller = props.inspection.systemInstaller;
    propertyRemarks = props.property.remarks;
    arresterCount = props.inspection.arresterCount;
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    submitForm(null);
  };

  const showTimeAmountControl = () => {
    return (
      followupSourceKind === InspectionKind.Initial ||
      kind === InspectionKind.Initial
    );
  };

  const followUpDeadlineControl = (
    <MarginFormItem label="Frist zur Behebung">
      {getFieldDecorator("date-picker-followUpDeadline", {
        initialValue: followUpDeadline ? moment(followUpDeadline) : null
      })(<DatePicker disabledDate={disabledFutureDate} format="DD.MM.YYYY" />)}
    </MarginFormItem>
  );

  const followupRequiredControl = (
    <MarginFormItem {...itemWithoutLabelLayout}>
      {getFieldDecorator("checkbox-isFollowUpRequired", {
        valuePropName: "checked",
        initialValue: isFollowUpRequired
      })(<Checkbox>Nachkontrolle nötig</Checkbox>)}
    </MarginFormItem>
  );

  const controlResult = getFieldValue(
    "select-control-result"
  ) as InspectionResult;

  const reminderRemarksControl =
    reminderState === ReminderState.Active ? (
      <MarginFormItem {...itemWithoutLabelLayout}>
        {getFieldDecorator("textarea-reminderremarks", {
          initialValue: reminderRemarks
        })(<TextArea rows={4} placeholder="Erinnerungstext" />)}
      </MarginFormItem>
    ) : null;

  const inspectionResultRemarks =
    controlResult === InspectionResult.FunctionLimited ? (
      <MarginFormItem {...itemWithoutLabelLayout}>
        {getFieldDecorator("textarea-inspection-remarks", {
          initialValue: remarks
        })(<TextArea rows={4} placeholder="Bemerkungen zum Kontrollergebnis" />)}
      </MarginFormItem>
    ) : null;

  const inspectionTypeMarksControl =
    getFieldValue("radio-inspectionType") === InspectionType.Uncertain
      //|| inspectionType === InspectionType.Uncertain 
      ? (
        <MarginFormItem {...itemWithoutLabelLayout}>
          {getFieldDecorator("textarea-inspection-inspectionTypeRemarks", {
            initialValue: inspectionTypeRemarks
          })(<TextArea rows={4} placeholder="Bemerkungen zum Kontrolltyp" />)}
        </MarginFormItem>
      ) : null;

  const timeAmountControl = showTimeAmountControl() ? (
    <MarginFormItem label="Zeitaufwand in Std" {...smallFields}>
      {getFieldDecorator("input-effort", {
        initialValue: effort,
        rules: [{ required: true, message: "Pflicht" }]
      })(<Input type="number" />)}
    </MarginFormItem>
  ) : null;

  const showControlsWhenFaulty =
    controlResult !== InspectionResult.FunctionalNoDefect ||
      kind !== InspectionKind.FollowUp ? (
        <>
          {followUpDeadlineControl} {followupRequiredControl}
        </>
      ) : null;

  const propertyIsNull = isNullOrUndefined(props.property);

  return (
    <Row>
      <Col lg={12} sm={24}>
        <Form
          labelAlign="left"
          id="inspectionForm"
          {...tailFormItemLayout}
          onSubmit={handleSubmit}
        >
          <Row>
            <Col>
              <MarginFormItem label="Kontrolldatum" {...reminderRemarks}>
                <RangePicker
                  showTime={{ format: "HH:mm" }}
                  format="DD.MM.YYYY HH:mm"
                  placeholder={["Von", "Bis"]}
                  value={[moment(startTime), moment(endTime)]}
                  disabled
                />
              </MarginFormItem>
              <MarginFormItem label="Kontaktperson">
                <Input disabled value={contactPersonName} />
              </MarginFormItem>

              <MarginFormItem label="Telefonnummer" {...tailFormItemLayout}>
                <Input
                  disabled
                  value={contactPersonPhone}
                  style={{ width: "100%" }}
                />
              </MarginFormItem>
{/* 
              <MarginFormItem label="Kontrolltyp" {...tailFormItemLayout}>
                {getFieldDecorator("radio-inspectionType", {
                  initialValue: inspectionType,
                  rules: [{ required: true, message: "Pflicht" }]
                })(
                  <Radio.Group buttonStyle="solid">
                    <Radio.Button value={InspectionType.Optional}>Freiwillig</Radio.Button>
                    <Radio.Button value={InspectionType.Mandatory}>Pflichtig</Radio.Button>
                    <Radio.Button value={InspectionType.Uncertain}>Unsicher</Radio.Button>
                  </Radio.Group>
                )
                }
              </MarginFormItem>

              {inspectionTypeMarksControl}

              <MarginFormItem label="Anzahl Ableiter" {...tailFormItemLayout}>
                {getFieldDecorator("input-arresterCount", {
                  initialValue: arresterCount
                })(<Input defaultValue="0" style={{ width: "100%" }} />)}
              </MarginFormItem>

              <MarginFormItem label="Kontrollergebnis" {...tailFormItemLayout}>
                {getFieldDecorator("select-control-result", {
                  initialValue: result,
                  rules: [{ required: true, message: "Pflicht" }]
                })(
                  <Select>
                    <Option value={InspectionResult.FunctionalNoDefect}>
                      Funktionstüchtig und mängelfrei
                    </Option>
                    <Option value={InspectionResult.FunctionLimited}>
                      Eingeschränkt funktionstüchtig
                    </Option>
                    <Option value={InspectionResult.Faulty}>Mangelhaft</Option>
                  </Select>
                )}
              </MarginFormItem>
              {inspectionResultRemarks}
              {showControlsWhenFaulty}

              {timeAmountControl}
              <MarginFormItem {...itemWithoutLabelLayout}>
                {getFieldDecorator("textarea-internalremarks", {
                  initialValue: propertyRemarks
                })(
                  <TextArea
                    disabled={propertyIsNull}
                    rows={4}
                    placeholder="Interne Bemerkungen zum Objekt"
                  />
                )}
              </MarginFormItem>
              {reminderRemarksControl} */}
            </Col>
          </Row>
          <Row style={{ marginTop: "10px" }}>
            <Col>
              {/* <Form.Item {...itemWithoutLabelLayout}>
                <Button
                  disabled={hasErrors(getFieldsError())}
                  icon="save"
                  htmlType="submit"
                  form="inspectionForm"
                  type="primary"
                >
                  Daten Speichern
                </Button>
              </Form.Item> */}
            </Col>
          </Row>
          <Row>
            <Col xl={23}>
              <UploadZone inspection={props.inspection} />
            </Col>
          </Row>
        </Form>
      </Col>
      <Col lg={12} sm={24}>
        {/* <Findings inspectionId={id} /> */}
      </Col>
    </Row>
  );
};

export default Form.create<Props>()(InspectionStep);