import * as React from "react";
import { Row, Col } from "antd";
import { Finding } from "../../../../../../../../../models";

interface Props{
    finding: Finding
}

const FindingDisplay = (props: Props) => {
    const {description, findingNumber, imageUrl} = props.finding;
    return(
        <Row style={{marginTop: '10px'}} justify="start">
            <Col xl={8} lg={24} sm={24}><img src={imageUrl} style={{width:200,height:200}} /></Col>
            <Col xl={16} lg={24} sm={24}>
                <Row><h4>Mangel: {findingNumber}</h4></Row>
                <Row>{description}</Row>
            </Col>
        </Row>
    )
}

export default FindingDisplay;