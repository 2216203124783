import * as React from "react";
import { Inspection, Property, InspectionKind, InspectionResult, InspectionType, Document } from "../../../../../../../models";
import { Row, Col, Descriptions, Checkbox, List } from "antd";
import moment from "moment";
import Findings from "../InspectionStep/Findings";
import { useEffect } from "react";
import { getDocumentsByInspection } from "../../../../../../../endpoints";

const { Item } = Descriptions;
interface Props {
  inspection: Inspection;
  property: Property;
}

const CompletedStep = (props: Props) => {
  const {
    contactPersonName,
    contactPersonPhone,
    startTime,
    remarks,
    internalRemarks,
    isInRenovation,
    isSystemInstalled,
    renovationDeadline,
    result,
    followUpDeadline,
    isFollowUpRequired,
    effort,
    id,
    inspectionType,
    inspectionTypeRemarks,
    isInspectionLamp,
    meterValues,
    systemInstaller,
    arresterCount,
    subsidyNumber,
    permitNumber
  } = props.inspection;

  const isInitialInspection = props.inspection.kind == InspectionKind.Initial;

  const systemInstalledCheckBox = isSystemInstalled ? "Ja" : "Nein";
  const isInRenovationCheckBox = isInRenovation ? "Ja" : "Nein";

  const followupControlRequired = isFollowUpRequired ? "Ja" : "Nein"

  const hasMeterValues = meterValues && meterValues.length > 0;

  const inspectionResult = (result: InspectionResult) => {
    if (result === InspectionResult.Faulty) return "Die BSA ist mangelhaft und muss ergänzt werden.";
    if (result === InspectionResult.FunctionLimited) return "Die BSA ist mit Einschränkungen funktionstüchtig.";
    if (result === InspectionResult.FunctionDegraded) return "Die BSA ist bedingt funktionstüchtig und muss ergänzt werden.";
    if (result === InspectionResult.FunctionalNoDefect) return " Die BSA ist funktionstüchtig und mängelfrei.";
  }

  const inspectionTypeDisplay = (type: InspectionType) => {
    if (type === InspectionType.Mandatory) return "pflichtige Anlage";
    if (type === InspectionType.Optional) return "freiwillige Anlage";
    if (type === InspectionType.Uncertain) return "unsicher";
  }

  const [documents, setDocuments] = React.useState<Document[]>([])

  useEffect(() => {

    const loadDocuments = async () => {
      const documents = await getDocumentsByInspection(id);
      setDocuments(documents);
    }

    loadDocuments();
  }, [])

  return (
    <>
      <Row >
        <Col span={12} style={{ marginLeft: '10px' }}>
          <Row style={{ marginTop: '10px' }}>
            <Col>

              <Descriptions column={1} size="small" bordered={true} title="Vorbereitungsinformationen">
                <Item label="Kontaktperson">{contactPersonName}</Item>
                <Item label="Telefon">{contactPersonPhone}</Item>

                <Item label="Kontrolldatum">{moment(startTime).format('DD.MM.YYYY')}</Item>
                {effort > 0 && (<Item label="Zeitaufwand in Std">{effort}</Item>)}
                <Item label="Anlage vorhanden">{systemInstalledCheckBox}</Item>

                <Item label="Objekt in Umbau">{isInRenovationCheckBox}</Item>
                <Item label="Frist Umbau">{renovationDeadline ? moment(renovationDeadline).format('DD.MM.YYYY') : ""}</Item>

                <Item label="Interne Bemerkungen zum Objekt">{internalRemarks}</Item>
                {
                  documents && documents.length > 0 && (
                    <Item label="Dokumente">
                      <List
                        size="small"
                        dataSource={documents}
                        renderItem={item => <List.Item><a href={item.uri}>{item.name}</a></List.Item>}
                      />

                    </Item>)
                }

              </Descriptions>
            </Col>
          </Row>
          <Row style={{ marginTop: '10px' }}>
            <Col>
              <Descriptions column={1} size="small" bordered={true} title="Durchführung">
                <Item label="Kontrollart">{inspectionTypeDisplay(inspectionType)}</Item>
                {inspectionType === InspectionType.Uncertain && (<Item label="Begründung">{inspectionTypeRemarks}</Item>)}

                <Item label="Anzahl Ableiter">{arresterCount}</Item>
                <Item label="Kontrollart">
                  <Checkbox checked={isInspectionLamp} onClick={() => false}  >Kontrolllampe</Checkbox>
                  <Checkbox checked={hasMeterValues} onClick={() => false}  >Widerstandsmessung</Checkbox>
                </Item>

                <Item label="Messergebnisse (Ω)">{meterValues ? meterValues.map(m => m.toFixed(2)).join(', ') : ""}</Item>

                <Item label="Kontrollergebnis">{inspectionResult(result)}</Item>
                <Item label="Bemerkungen">{remarks}</Item>
                {followUpDeadline && (<Item label="Frist zur Behebung">{moment(followUpDeadline).format('DD.MM.YYYY')}</Item>)}
                <Item label="Nachkontrolle nötig">{followupControlRequired}</Item>

                {isInitialInspection && (<Item label="Anlagenersteller">{systemInstaller}</Item>)}
                {isInitialInspection && (<Item label="Subventions-Nr">{subsidyNumber}</Item>)}
                {isInitialInspection && (<Item label="Bewilligungs-Nr">{permitNumber}</Item>)}
              </Descriptions>
            </Col>
          </Row>
        </Col>
        <Col span={11} style={{ marginLeft: '30px' }}>
          <Findings inspectionId={id} />
        </Col>
      </Row>

    </>
  );
};

export default CompletedStep;
