import React from 'react';
import {User} from '../models';

interface userModel{
    user: User
}

const userContext = React.createContext<userModel>({
    user: {} as User,
});

export default userContext;