import React from "react";

import "devextreme/data/odata/store";
import {
  Column,
  DataGrid,
  FilterRow,
  HeaderFilter,
  GroupPanel,
  Scrolling,
  Grouping,
  StateStoring,
  Sorting,
  RemoteOperations,
  Editing,
  Button as DxButton
} from "devextreme-react/data-grid";
import { createStore } from "devextreme-aspnet-data-nojquery";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.material.blue.light.compact.css";
import { Redirect, RouteComponentProps } from "react-router-dom";
import {
  Inspection,
  InspectionKind,
  InspectionStatus,
  InspectionStatusDisplay,
  InspectionKindDisplay,
  User,
  InspectorType
} from "../../../../models";
import { Button, Row, Col } from "antd";
import { authHeader } from "../../../../authentication/auth-helper";
import { createInspection } from "../../../../endpoints";
import "devextreme-intl";
import { loadMessages, locale } from "devextreme/localization";
import deMessages from "devextreme/localization/messages/de.json";
import CanceledInspections from "./CancelledInspections";
import { authenticationService } from "../../../../authentication/authenticationService";
import moment from "moment";
import Grid from "antd/lib/card/Grid";
import { stringify } from "querystring";
import openExternalIcon from "../../../../assets/open_external.svg";

interface State {
  redirect: boolean;
  selectedInspection: Inspection | null;
  storeLayoutState: boolean;
  expandedColumnState?:any[]
  isCtrlDown:boolean;
}

interface Props extends RouteComponentProps<any> { }
class InspectionList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    loadMessages(deMessages);
    locale("de-CH");
    this.state = {
      redirect: false,
      selectedInspection: null,
      storeLayoutState: true,
      expandedColumnState:[],
      isCtrlDown:false

    };
    //@ts-ignore
    this.grid = React.createRef();
  }

  getUserOrLogout = (): User => {
    const currentUser: User = authenticationService.currentUserValue;

    if (!currentUser || !currentUser.token || !currentUser.id) {
      authenticationService.logout();
      this.props.history.push("/login");
    }

    return currentUser;
  };
  dataSource = createStore({
    key: "id",
    onBeforeSend: (method, param) => {
      const bearerToken = authHeader();
      param.headers = {
        Authorization: bearerToken
      };
      const currentUser = this.getUserOrLogout();
      param.url = `${process.env.REACT_APP_API_BASE_URI}/inspectors/${currentUser.id}/inspections/dxdataSource`;
    }
  });

  isRegionSupervisor =
    authenticationService.currentUserValue &&
    authenticationService.currentUserValue.inspectorType ===
    InspectorType.RegionSupervisor;

  onSelectionChanged = (selectedItems: any) => {
    const selectedItem = selectedItems.selectedRowsData[0] as Inspection;
    localStorage.setItem("expandedColumnsState", JSON.stringify(this.state.expandedColumnState));
    this.setState({ redirect: true, selectedInspection: selectedItem });
    
  };

  newInspectionHandler = async (): Promise<void> => {
    const createNewInspection = async (): Promise<Inspection> => {
      const newInspection: Inspection = {
        kind: InspectionKind.Initial,
        status: InspectionStatus.Open,
        isSystemInstalled: true,
        inspectorId: authenticationService.currentUserValue.id
      };

      return await createInspection(newInspection);
    };

    const createdInspection: Inspection = await createNewInspection();

    this.props.history.push(`/kontrollen/${createdInspection.id}`);
  };

  isDeadlineExpired = (inspectionDeadline: Date): boolean => {
    const deadline = moment(inspectionDeadline);
    return moment().isAfter(deadline);
  };

  async expandGroupChildren(component: any, parentKey: number) {
    const row = await component.byKey([parentKey]);
    if (!row || !row.collapsedItems) return;

    row.collapsedItems.forEach(item => {
      component.expandRow([parentKey, item.key]);
    });

  }

  resetLayout = () => {

    localStorage.removeItem("dxGridStorage");
    localStorage.removeItem("expandedColumnsState");
    // this.setState({ storeLayoutState: false });
    document.location.reload();
  }


  render() {
    //@ts-ignore
    if (this.state.redirect) {
      if (this.state.selectedInspection !== null) {
        this.props.history.push(`/kontrollen/${this.state.selectedInspection.id}`);
        // return (
        //   <Redirect to={`/kontrollen/${this.state.selectedInspection.id}`} />
        // );
      }
    }

    return (
      <>
        <Row>
          <Col>
            <Row style={{ marginTop: "20px" }}>
              <Col offset={1} span={18}>
                <CanceledInspections
                  inspectorId={authenticationService.currentUserValue.id}
                />
              </Col>
            </Row>

            <Row style={{ marginTop: "20px" }}>
              <Col offset={1} span={22}>
                {this.isRegionSupervisor && (<Button onClick={this.newInspectionHandler} type={"primary"} style={{ marginRight: 10 }}>
                  Neue Kontrolle
                </Button>)}
                <Button onClick={this.resetLayout}>
                  Ansicht zurücksetzen</Button>
              </Col>
            </Row>

            <Row style={{ marginTop: "20px" }}>

              <Col offset={1} span={22}>

                <DataGrid

                  //@ts-ignore
                  ref={this.grid}
                  //@ts-ignore
                  dataSource={this.dataSource}
                  selection={{ mode: "single" }}
                  hoverStateEnabled={true}
                  showBorders={true}
                  allowColumnResizing={true}
                  columnMinWidth={150}
                  allowColumnReordering={true}
                  remoteOperations={true}
                  onSelectionChanged={this.onSelectionChanged}
                  onRowExpanded={(e)=>{
                   this.state.expandedColumnState.push(e.key);
                  }}
                  onRowCollapsed={(e)=>{
                   
                   }}
                  onRowPrepared={(e: any) => {
                    if (e.rowType == 'data'
                      && e.data.status !== InspectionStatus.Completed
                      && this.isDeadlineExpired(e.data.inspectionDeadline)
                    ) {
                      e.rowElement.style.color = 'red';
                      e.rowElement.className = e.rowElement.className.replace("dx-row-alt", "");
                    }

                  }
                  }

                  onContentReady={async (e: any) => {
                    const gridComponent = e.component;

                    if (!gridComponent.___avaInitiallyExpanded) {
                      gridComponent.___avaInitiallyExpanded = true;

                      const statusColumnIndex = gridComponent.getVisibleColumnIndex("status");
                      if (statusColumnIndex !== 0) return;

                      this.expandGroupChildren(gridComponent, InspectionStatus.Open);
                      this.expandGroupChildren(gridComponent, InspectionStatus.Started);

                      gridComponent.expandRow([InspectionStatus.Open]);
                      gridComponent.expandRow([InspectionStatus.Started]);

                      this.state.expandedColumnState.forEach(e=>{


                      });
                    }
                  }}

                  onContextMenuPreparing={(e) => {
                    if (e.column.dataField === "assuranceNumber") {
                      // const item = e.items.find(i=>i.value ==="none");
                      e.items = e.items.filter(item => item.value !== "none");
                    }
                  }}
                >
                  <FilterRow visible={true} />
                  <HeaderFilter visible={true} />
                  <GroupPanel visible={true} />
                  <Scrolling mode="virtual" showScrollbar="always" />
                  <Sorting mode="multiple" />
                  <Grouping autoExpandAll={false} />
                  <RemoteOperations grouping={true} sorting={true} />
                  <StateStoring enabled={this.state.storeLayoutState} type="localStorage" storageKey="dxGridStorage" />

                  <Column
                    caption={"Versicherungsnummer"}
                    dataField={"assuranceNumber"}
                    allowSorting={true}
                    defaultSortOrder="asc"
                    defaultSortIndex="1"
                  />
                  <Column
                    caption={"Nutzungsart"}
                    dataField={"usageKind"}
                    allowSorting={true}
                    width={90}
                  />
                  <Column
                    caption={"Kontaktperson"}
                    dataField={"contactPersonName"}
                    allowSorting={true}
                  />

                  <Column
                    caption={"Verwalter"}
                    dataField={"administratorName"}
                    allowSorting={true}
                  />

                  <Column
                    caption={"Strasse"}
                    dataField={"street"}
                    allowSorting={true}
                  // defaultSortOrder="asc"
                  // defaultSortIndex="1"
                  />
                  <Column
                    caption={"Haus Nr"}
                    dataField={"streetNumber"}
                    allowSorting={true}
                    minWidth={100}
                  // defaultSortOrder="asc"
                  // defaultSortIndex="2"
                  />
                  <Column
                    caption={"Grundbuchkreis"}
                    dataField={"municipality"}
                    groupIndex={1}
                    allowSorting={true}
                  />
                  <Column
                    caption={"Kontrolltyp"}
                    dataField={"kind"}
                    allowSorting={true}
                    lookup={{
                      dataSource: InspectionKindDisplay,
                      valueExpr: "key",
                      displayExpr: "displayValue"
                    }}
                  />
                  <Column
                    caption={"Status"}
                    dataField={"status"}
                    allowSorting={true}

                    groupIndex={0}
                    lookup={{
                      dataSource: InspectionStatusDisplay,
                      valueExpr: "key",
                      displayExpr: "displayValue"
                    }}
                  />
                  <Column
                    caption={"Kontrolldatum"}
                    dataField={"startTime"}
                    dataType="date"
                    format="shortDate"
                    allowSorting={true}
                    minWidth={100}
                  />
                  <Column
                    caption={"Frist"}
                    dataField={"inspectionDeadline"}
                    dataType="date"
                    format="shortDate"
                    allowSorting={true}
                    minWidth={100}
                  />      
                  
                  <Column type="buttons" width={24}>
                      <DxButton 
                      cssClass='dx-datagrid-btn-newwindow'
                      text="Open"
                      icon={openExternalIcon}
                      onClick={ (e)=>{
                        const inspection = e.row.data as Inspection;
                        window.open(`/kontrollen/${inspection.id}`);                         
                      }
                    }
                    />
                  </Column>
                  
                </DataGrid>

              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }
}

export default InspectionList;
