import * as React from "react";
import { Row, Col, Card, Tag } from "antd";
import InspectionContext from "../../../../../context/inspection-context";
import SharepointDocumentLink from "../../../../shared/SharepointDocumentLink";
import GeoPortalLink from "../../../../shared/GeoPortalLink";

const PropertyDataDisplay = () => {
  const inspectionContext = React.useContext(InspectionContext);

  function replaceNull(str: string) {
    if (!str) return "";
    return str.replace("null", "");
  }

  //Brandschutz
  function getFirewallNumbers() {
    if (
      !inspectionContext.property.adjacentProperties ||
      inspectionContext.property.adjacentProperties.length == 0
    )
      return "";

    return inspectionContext.property.adjacentProperties.map(x => (
      <Tag key={x.assuranceNumber} color={x.hasFirewall ? "green" : "red"}>
        {x.assuranceNumber}
      </Tag>
    ));
  }

  function buildAdministrationBlock() {
    if (!inspectionContext.property.administrator) {
      return (
        <Row>
          <Col lg={6}>
            <span style={{ fontWeight: "bold" }}>Verwalter</span>
          </Col>
          <Col lg={10}>
            <span style={{ fontStyle: "italic" }}>Kein Verwalter</span>>
          </Col>
        </Row>
      );
    } else {
      const {
        name,
        firstname,
        street,
        streetNumber,
        zipCode,
        city
      } = inspectionContext.property.administrator;
      return (
        <Row>
          <Row>
            <Col lg={7}>
              <span style={{ fontWeight: "bold" }}>Verwalter</span>
            </Col>

            <Col lg={10}>
              {firstname} {name}
            </Col>
          </Row>
          <Row>
            <Col lg={7}>
              <span>&nbsp;</span>
            </Col>
            <Col lg={10}>
              {street} {streetNumber}
            </Col>
          </Row>
          <Row>
            <Col lg={7}>
              <span>&nbsp;</span>
            </Col>
            <Col lg={10}>
              {zipCode} {city}
            </Col>
          </Row>
        </Row>
      );
    }
  }

  let {
    usageKind,
    bfsNumber,
    assuranceNumberGeoPortal,
    municipality,
    municipalityCode,
    assuranceNumber,
    cubature,
    street,
    streetNumber
  } = inspectionContext.property;

  municipality = replaceNull(municipality);
  street = replaceNull(street);
  streetNumber = replaceNull(streetNumber);

  const adjacentProperties = getFirewallNumbers();
  const administratorColumn = buildAdministrationBlock();

  return (
    <>
      <Row type="flex" justify="center">
        <Col lg={24} md={12} sm={24} xs={24}>
          <Card
            size={"small"}
            headStyle={{ backgroundColor: "#1890ff", color: "#fff" }}
            style={{ marginTop: 16, minHeight: 170 }}
            title="Stammdaten"
          >
            <Col lg={6} sm={12}>
              <Row>
                <Col lg={7}>
                  <span style={{ fontWeight: "bold" }}>Adresse</span>
                </Col>
                <Col lg={10}>
                  {street} {streetNumber}
                </Col>
              </Row>
              <Row>
                <Col lg={7} style={{ fontWeight: "bold" }}>
                Grundbuchkreis
                </Col>
                <Col lg={10}>{municipality}</Col>
              </Row>
              <Row>
                <Col lg={7} style={{ fontWeight: "bold" }}>
                  Kubatur
                </Col>
                <Col lg={10}>
                  {cubature} m<sup>3</sup>
                </Col>
              </Row>
              <Row>
                <Col lg={7} style={{ fontWeight: "bold" }}>
                  Nutzungsart
                </Col>
                <Col lg={10}>{usageKind}</Col>
              </Row>
            </Col>
            <Col lg={6} sm={12}>
              <Row>
                <Col lg={7}>
                  <span style={{ fontWeight: "bold" }}>Brandmauer</span>
                </Col>
                <Col lg={16}>{adjacentProperties}</Col>
              </Row>
              <Row>
                <Col lg={7}>
                  <span style={{ fontWeight: "bold" }}>Lageplan</span>
                </Col>
                <Col lg={10}><GeoPortalLink bfsNumber={bfsNumber} assuranceNumberGeoPortal={assuranceNumberGeoPortal} /> </Col>
              </Row>
              <Row>
                <Col lg={7}>
                  <span style={{ fontWeight: "bold" }}>Dokumentation</span>
                </Col>
                <Col lg={10}>
                  <SharepointDocumentLink
                    municipality={municipality}
                    municipalityCode={municipalityCode}
                    assuranceNumber={assuranceNumber}
                  />
                </Col>
              </Row>
            </Col>
            <Col lg={6} sm={12}>
              {administratorColumn}
            </Col>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default PropertyDataDisplay;
