import React from "react";

import "devextreme/data/odata/store";
import {
  Column,
  DataGrid,
  FilterRow,
  HeaderFilter,
  GroupPanel,
  Scrolling,
  Grouping
} from "devextreme-react/data-grid";
import { createStore } from "devextreme-aspnet-data-nojquery";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.material.blue.light.compact.css";
import { Redirect, RouteComponentProps } from "react-router-dom";
import {
  Inspection,
  InspectionKind,
  InspectionStatus,
  InspectionStatusDisplay,
  InspectionKindDisplay,
  User
} from "../../../../models";
import { Row, Col } from "antd";
import {
  authHeader
} from "../../../../authentication/auth-helper";
import { createInspection, removeInspectionFromInbox } from "../../../../endpoints";
import "devextreme-intl";
import { loadMessages, locale } from "devextreme/localization";
import deMessages from "devextreme/localization/messages/de.json";
import { authenticationService } from "../../../../authentication/authenticationService";

interface State {
  redirect: boolean;
  selectedInspection: Inspection | null;
}

interface Props extends RouteComponentProps<any> { }
class Inbox extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    loadMessages(deMessages);
    locale("de-CH");
    this.state = {
      redirect: false,
      selectedInspection: null
    };
  }

  getUserOrLogout = (): User => {
    const currentUser: User = authenticationService.currentUserValue;

    if (!currentUser || !currentUser.token || !currentUser.id) {
      authenticationService.logout();
      this.props.history.push("/login");
    }

    return currentUser;
  };



  dataSource = createStore({
    key: "id",
    onBeforeSend: (method, param) => {
      const beararToken = authHeader();

      param.headers = {
        Authorization: beararToken
      };

      const currentUser = this.getUserOrLogout();
      param.url = `${process.env.REACT_APP_API_BASE_URI}/inspectors/${currentUser.id}/reminders/dxdataSource`;
    }
  });

  onSelectionChanged = async (selectedItems: any) => {
    const selectedItem = selectedItems.selectedRowsData[0] as Inspection;
    await this.removeFromInbox(selectedItem);
    this.setState({ redirect: true, selectedInspection: selectedItem });
  };

  removeFromInbox = async (inspection: Inspection) => {
    removeInspectionFromInbox(inspection.id);
  }

  render() {
    if (this.state.redirect) {
      if (this.state.selectedInspection !== null) {
        this.props.history.push(`/kontrollen/${this.state.selectedInspection.id}`);

        // return (
        //   <Redirect
        //     to={`/kontrollen/${this.state.selectedInspection.id}`}
        //   />
        // );
      }
    }

    return (
      <Row>
        <Col>

          <Row style={{ marginTop: "20px" }}>
            <Col offset={1} span={22}>
              <DataGrid
                //@ts-ignore
                dataSource={this.dataSource}
                selection={{ mode: "single" }}
                hoverStateEnabled={true}
                showBorders={true}
                allowColumnResizing={true}
                allowColumnReordering={true}
                remoteOperations={true}
                onSelectionChanged={this.onSelectionChanged}
              >
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                <GroupPanel visible={false} />
                <Scrolling mode={"virtual"} />

                <Grouping autoExpandAll={false} />
                <Column
                  width={200}
                  caption={"Typ"}
                  dataField={"inboxEntryType"}
                  allowSorting={true}
                />
                <Column
                  width={100}
                  caption={"Vers.Nr"}
                  dataField={"assuranceNumber"}
                  allowSorting={true}
                />

                <Column
                  width={250}
                  caption={"Kontaktperson"}
                  dataField={"contactPersonName"}
                  allowSorting={true}
                />
                <Column
                  caption={"Strasse"}
                  dataField={"street"}
                  allowSorting={false}
                />
                <Column

                  caption={"Erinnerung"}
                  dataField={"reminderRemarks"}
                  allowSorting={false}
                />
                <Column
                  caption={"Grundbuchkreis"}
                  dataField={"municipality"}
                  allowSorting={true}
                />

                <Column
                  caption={"Kontrolldatum"}
                  dataField={"startTime"}
                  dataType="date"
                  format="shortDateShortTime"
                  allowSorting={true}
                />
              </DataGrid>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default Inbox;
