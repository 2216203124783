import React from "react";
import Alert from "antd/lib/alert";
interface State{
    hasError: boolean;
}
class ErrorBoundary extends React.Component<any, State>{
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {
      // You can also log the error to an error reporting service
      console.error("from errorboundary", error, errorInfo);
    }
  
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return (        
          <div style={{margin: '50px'}}><Alert message="Es ist ein Fehler aufgetreten." type="error" /></div>
          );
      }
  
      return this.props.children; 
    }
  }

  export default ErrorBoundary;