import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { authenticationService } from '../../../authentication/authenticationService';
import { User } from '../../../models';
import UserContext from '../../../context/user-context';

export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => {
        const currentUser: User = authenticationService.currentUserValue;

        if (!currentUser || !currentUser.token) {
            // not logged in so redirect to login page with the return url
            authenticationService.logout();
            return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        }

        // authorised so return component
        return <UserContext.Provider value={{user: currentUser}}> <Component {...props} /></UserContext.Provider>
    }} />
)