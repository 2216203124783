import * as React from "react";
import { Row, Col, message, Modal, Skeleton, Spin, Icon, Alert } from "antd";
import { RouteComponentProps } from "react-router";
import {
  Inspection,
  Property,
  InspectionStatus,
  InspectionKindDisplay,
  ReminderState
} from "../../../../models";
import {
  getInspectionById,
  getPropertyById,
  updateProperty,
  createProperty,
  updateInspection,
  deleteInspection,
  deleteProperty
} from "../../../../endpoints";
import InspectionAssistant from "./InspectionAssistant";

import InspectionContext from "../../../../context/inspection-context";
import { isNullOrUndefined } from "util";
import PropertyEditDialog from "./PropertyEditDialog";
import Toolbar from "./Toolbar";
import PropertyDataDisplay from "./PropertyDataDisplay";
import Title from "antd/lib/typography/Title";
import Notifications from "./Notifications/notifications";

const { confirm } = Modal;

interface State {
  currentInspection: Inspection;
  currentProperty: Property;

  currentStep: number;
  showPropertyDialog: boolean;
  loadingData: boolean;
  noSystemIsInstalled: boolean;
  objectIsInRenovation: boolean;
}
type RouteParams = {
  inspectionId: string; // must be type string since route params
};

interface Props extends RouteComponentProps<RouteParams> {
  updateInspection: (inspection: Inspection) => void;
}

class InspectionsDetail extends React.Component<Props, State> {
  formRef: any;
  stepFormRef: any;
  submitFormRef: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      loadingData: true,
      noSystemIsInstalled: false,
      objectIsInRenovation: false,
      currentInspection: {} as Inspection,
      currentProperty: null,
      currentStep: 0,
      showPropertyDialog: false
    };
  }

  async componentDidMount() {
    message.loading("Kontrolle wird geladen...", 0);
    let propertyResult: Property = null;
    const inspectionResult = await getInspectionById(
      this.props.match.params.inspectionId
    );
    if (inspectionResult.propertyId) {
      propertyResult = await getPropertyById(inspectionResult.propertyId);
    }

    const currentStep =
      !inspectionResult || !inspectionResult.status
        ? InspectionStatus.Open
        : inspectionResult.status;

    message.destroy();
    message.success("Kontrolle erfolgreich geladen", 2);
    this.setState({
      currentInspection: inspectionResult,
      currentProperty: propertyResult,
      currentStep: currentStep,
      showPropertyDialog: !propertyResult,
      noSystemIsInstalled: !inspectionResult.isSystemInstalled,
      objectIsInRenovation: inspectionResult.isInRenovation,
      loadingData: false
    });
  }

  updateInspectionHandler = (inspection, property, newStatus) => {
    const status = newStatus !== null ? newStatus : this.state.currentStep;
    this.updateInspection(
      {
        ...inspection,
        status: status
      },
      property
    );

    if (this.state.currentStep !== status) {
      this.setState({
        currentStep: newStatus
      });
    }
  };

  nextStepHandler = () => {
    const newStatus = this.state.currentStep + 1;
    this.submitFormRef.current.submit(newStatus);
  };

  completeStepHandler = () => {
    const newStatus = 2;
    this.submitFormRef.current.submit(newStatus);
  };

  previousStepHandler = async () => {
    const newStatus = this.state.currentStep - 1;
    await updateInspection({
      ...this.state.currentInspection,
      status: newStatus
    });
    this.setState({
      currentStep: newStatus
    });
  };

  inspectionDeleteHandler = async (inspection: Inspection, props: any) => {
    confirm({
      title: "Kontrolle löschen?",
      okText: "Löschen",
      cancelText: "Abbrechen",
      async onOk() {
        message.loading("Kontrolle wird gelöscht...", 0);
        await deleteInspection(inspection.id);

        if (inspection.propertyId) {
          await deleteProperty(inspection.propertyId);
        }

        message.success("Kontrolle wurde gelöscht", 2);
        message.destroy();
        props.history.push(`/kontrollen`);
      },
      onCancel() {}
    });
  };

  propertyDialogCancelHandler = () => {
    this.setState({ showPropertyDialog: false });
  };

  propertyDialogOkHandler = (property: Property, inspection: Inspection) => {
    const { form } = this.formRef.props;

    form.validateFields(err => {
      if (err) {
        console.log(err);
        return;
      }

      this.saveProperty(property, inspection).then(() => {
        form.resetFields();
      });

      this.setState({ showPropertyDialog: false });
    });
  };

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  saveStepFormRef = formRef => {
    this.stepFormRef = formRef;
  };

  saveProperty = async (newProperty: Property, updatedInspection: Inspection): Promise<void> => {
    message.loading("Gebäudedaten werden gespeichert...", 0);

    if (!this.state.currentProperty) {
      const newCreatedProperty: Property = await createProperty(newProperty);

      const inpsectionUpdate: Inspection = {
        ...this.state.currentInspection,
        propertyId: newCreatedProperty.id,
        subsidyNumber: updatedInspection.subsidyNumber,
        permitNumber: updatedInspection.permitNumber,
        systemInstaller: updatedInspection.systemInstaller,
        affectedBuildingParts: updatedInspection.affectedBuildingParts,
      };

      await updateInspection(inpsectionUpdate);

      this.setState({
        currentProperty: newCreatedProperty,
        currentInspection: inpsectionUpdate
      });
    } else {
      const inpsectionUpdate: Inspection = {
        ...this.state.currentInspection,       
        subsidyNumber: updatedInspection.subsidyNumber,
        permitNumber: updatedInspection.permitNumber,
        systemInstaller: updatedInspection.systemInstaller,
        affectedBuildingParts: updatedInspection.affectedBuildingParts,
      };

      await updateInspection(inpsectionUpdate);

      const combinedProperty: Property = {
        ...this.state.currentProperty,
        ...newProperty
      };
      
      await updateProperty(combinedProperty);
      this.setState({ currentProperty: combinedProperty });
    }

    message.destroy();
    message.success("Gebäudedaten erfolgreich gespeichert", 2);
  };

  saveSubmitFormRef = ref => {
    this.submitFormRef = ref;
  };

  updateInspection = async (
    inspection: Inspection,
    property: Property
  ): Promise<void> => {
    message.loading("Kontrolle wird gespeichert...", 0);

    const newInspection = { ...this.state.currentInspection, ...inspection };
    let newProperty = { ...this.state.currentProperty };
    await updateInspection(newInspection);

    if (property) {
      newProperty = { ...this.state.currentProperty, ...property };
      await updateProperty(newProperty);
    }

    this.setState({
      currentInspection: newInspection,
      currentProperty: newProperty
    });

    message.destroy();

    message.success("Kontrolle erfolgreich gespeichert", 2);
  };

  onChangeSystemInstalledHandler = (element: any) => {
    this.setState({
      noSystemIsInstalled: element.target.checked
    });
  };

  onChangeIsInRenovationHandler = (element: any) => {
    this.setState({
      objectIsInRenovation: element.target.checked
    });
  };

  render() {
    const { currentInspection, currentProperty } = this.state;
    const inspectionInfo = isNullOrUndefined(currentProperty) ? null : (
      <PropertyDataDisplay />
    );

    const model = InspectionKindDisplay.find(
      t => t.key === this.state.currentInspection.kind
    );

    return (
      <InspectionContext.Provider
        value={{
          inspection: currentInspection,
          property: currentProperty,
          submitFormRef: this.saveSubmitFormRef,
          onChangeSystemInstalled: this.onChangeSystemInstalledHandler,
          onChangeObjectIsInRenovation: this.onChangeIsInRenovationHandler,
          updateInspectionHandler: this.updateInspectionHandler
        }}
      >
        <Skeleton loading={this.state.loadingData}>
          <PropertyEditDialog
            Property={this.state.currentProperty}
            wrappedComponentRef={this.saveFormRef}
            visible={this.state.showPropertyDialog}
            onCancel={this.propertyDialogCancelHandler}
            onCreate={this.propertyDialogOkHandler}
            inspection={currentInspection}
          />

          <Row style={{ marginTop: "15px" }} type="flex" justify="center">
            <Col span={22}>
              {model && this.state.currentProperty && (
                <Title level={3}>
                  {model.displayValue}{" "}
                  {this.state.currentProperty.assuranceNumber}
                </Title>
              )}
            </Col>
          </Row>
          <Row style={{ marginTop: "0px" }} type="flex" justify="center">
            <Col span={22}>{inspectionInfo}</Col>
          </Row>

          <Notifications
            isReminderFlagSet={
              this.state.currentInspection.reminderState ===
              ReminderState.Active
            }
            canComplete={
              this.state.currentStep === 1 ||
              (this.state.currentStep === 0 &&
                this.state.currentInspection.startTime !== null) &&
                (this.state.noSystemIsInstalled ||
                  this.state.objectIsInRenovation)
            }
            assistantStep={this.state.currentStep}
            date={this.state.currentInspection.startTime}
          />

          <Row style={{ marginTop: "10px" }} type="flex" justify="center">
            <Col lg={22}>
              <Toolbar
                inspection={this.state.currentInspection}
                currentStep={this.state.currentStep}
                onDeleteInspection={() =>
                  this.inspectionDeleteHandler(currentInspection, this.props)
                }
                onNewProperty={() =>
                  this.setState({ showPropertyDialog: true })
                }
                onNextStep={this.nextStepHandler}
                onCompleteStep={this.completeStepHandler}
                onPrevStep={this.previousStepHandler}
                noSystemIsInstalled={this.state.noSystemIsInstalled}
                objectIsInRenovation={this.state.objectIsInRenovation}
                propertyExists={!isNullOrUndefined(this.state.currentProperty)}
              />
              <InspectionAssistant
                objectIsInRenovation={this.state.objectIsInRenovation}
                isNotSystemInstalled={this.state.noSystemIsInstalled}
                updateInspection={this.updateInspection}
                property={this.state.currentProperty}
                inspection={this.state.currentInspection}
                currentStep={this.state.currentStep}
                stepFormRef={this.saveStepFormRef}
              />
            </Col>
          </Row>
        </Skeleton>
      </InspectionContext.Provider>
    );
  }
}

export default InspectionsDetail;
