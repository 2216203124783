import * as React from "react";
import { MarginComponent } from "../../../../shared/base-styles";
import { Button } from "antd";
import {
  Inspection,
  InspectionKind,
  ReminderState
} from "../../../../../models";

import moment from "moment";

interface ToolbarProps {
  currentStep: number;
  onNextStep: () => void;
  onCompleteStep: () => void;
  onPrevStep: () => void;
  onDeleteInspection: () => void;
  onNewProperty: () => void;
  propertyExists: boolean;
  inspection: Inspection;
  noSystemIsInstalled: boolean;
  objectIsInRenovation: boolean;
}

const Toolbar = (props: ToolbarProps) => {
  
  const { inspection, propertyExists } = props;

  const { reminderState, kind, startTime } = inspection;

  const editPropertyButton = (
    <Button
      style={{ marginLeft: "5px" }}
      icon="edit"
      onClick={props.onNewProperty}
    >
      {!propertyExists ? "Neues Gebäude erfassen" : "Gebäudedaten ändern"}
    </Button>
  );

  const isInFuture = (): boolean => {
    return !moment().isAfter(startTime);
  };

  const deleteButton = (
    <Button
      style={{ marginLeft: "5px" }}
      type="danger"
      icon="delete"
      onClick={() => props.onDeleteInspection()}
    >
      Kontrolle löschen
    </Button>
  );

  const completeButtonInStepInspectionStep = (
    <Button
      icon="check"
      type="primary"
      disabled={reminderState === ReminderState.Active || isInFuture()}
      style={{ marginLeft: 8 }}
      onClick={() => props.onNextStep()}
    >
      Kontrolle abschliessen
    </Button>
  );

  const completeButtonInPreperationStep = (
    <Button
      icon="check"
      disabled={reminderState === ReminderState.Active || isInFuture()}
      type="primary"
      onClick={() => props.onCompleteStep()}
    >
      Kontrolle abschliessen
    </Button>
  );

  const controlPreperationButton = (
    <Button
      icon="folder-open"
      type="primary"
      style={{ marginLeft: 8 }}
      onClick={() => props.onPrevStep()}
    >
      Kontrolle vorbereiten
    </Button>
  );

  const controlStartButton = (
    <Button disabled={props.inspection.startTime === null} type="primary" onClick={() => props.onNextStep()}>
      Kontrolle starten
    </Button>
  );  
  
  return (
    <MarginComponent top="10px" bottom="10px" className="steps-action">
      {props.currentStep === 0 && (
        <>
          {!props.noSystemIsInstalled && !props.objectIsInRenovation
            ? controlStartButton
            : completeButtonInPreperationStep}
          {kind === InspectionKind.Initial ? (
            <>
              {deleteButton}
              {editPropertyButton}
            </>
          ) : null}
        </>
      )}
      {props.currentStep === 1 && (
        <>
          {controlPreperationButton}
          {/* {completeButtonInStepInspectionStep} */}
          {kind === InspectionKind.Initial ? editPropertyButton : null}
        </>
      )}
    </MarginComponent>
  );
};

export default Toolbar;
