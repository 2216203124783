import "antd/dist/antd.css";
import React, { useEffect, useState } from "react";

import { Descriptions } from "antd";
import {
  getInspectionById,
  getPortalConfirmationPhotos,
} from "../../../../../../../../endpoints";
import { PortalConfirmationPhotos } from "./PortalConfirmationPhotos";
import { MarginFormItem, tailFormItemLayout } from "../../commonFormStyling";
import { ConfirmationPersonFunction } from "../../../../../../../../models";
import dayjs from "dayjs";

export interface PortalConfirmationDetailsProps {
  inspectionId?: string;
}
export const PortalConfirmationDetails = (
  props: PortalConfirmationDetailsProps
) => {
  const [inspection, setInspection] = useState();
  const [photos, setPhotos] = useState();

  const loadInspection = async () => {
    const inspection = await getInspectionById(props.inspectionId);
    setInspection(inspection);
    const documents = await getPortalConfirmationPhotos(inspection.id);
    if (documents) {
      setPhotos(documents.map((d) => d.uri));
    }
  };

  const contactPersonFunctionDisplayName = (functionId: number): string => {
    if (functionId === ConfirmationPersonFunction.Administrator)
      return "Verwalter";
    if (functionId === ConfirmationPersonFunction.Janitor) return "Hauswart";
    if (functionId === ConfirmationPersonFunction.Owner) return "Eigentümer";
    if (functionId === ConfirmationPersonFunction.Tinsmith) return "Spengler";
    if (functionId === ConfirmationPersonFunction.Electrician)
      return "Elektriker";
  };

  useEffect(() => {
    loadInspection();
  }, []);

  return (
    <>
      {inspection && inspection.confirmationInformation && (
        <div>
          <h3>Bestätigung Mängelbehebung</h3>

          {inspection && inspection.confirmationInformation && (
            <div>
              <MarginFormItem
                style={{ marginBottom: 0 }}
                {...tailFormItemLayout}
                label="Name"
              >
                <div style={{ marginLeft: 20 }}>
                  {inspection.confirmationInformation.contactFirstname}{" "}
                  {inspection.confirmationInformation.contactLastname}
                </div>
              </MarginFormItem>

              <MarginFormItem {...tailFormItemLayout} label="Telefonnummer">
                <div style={{ marginLeft: 20 }}>
                  {inspection.confirmationInformation.contactPhone}
                </div>
              </MarginFormItem>

              <MarginFormItem {...tailFormItemLayout} label="Funktion">
                <div style={{ marginLeft: 20 }}>
                  {contactPersonFunctionDisplayName(
                    inspection.confirmationInformation.contactPersonFunction
                  )}
                </div>
              </MarginFormItem>

              <MarginFormItem {...tailFormItemLayout} label="Bemerkungen">
                <div style={{ marginLeft: 20 }}>
                  {inspection.confirmationInformation.description}
                </div>
              </MarginFormItem>

              <MarginFormItem {...tailFormItemLayout} label="Bestätigt am">
                <div style={{ marginLeft: 20 }}>
                  {dayjs(
                    inspection.confirmationInformation.confirmationDate
                  ).format("DD.MM.YYYY HH:mm")}
                </div>
              </MarginFormItem>
            </div>
          )}

          {inspection && inspection.confirmationInformation && photos && (
            <PortalConfirmationPhotos photos={photos} />
          )}
        </div>
      )}
    </>
  );
};
