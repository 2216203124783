import React, { useState } from "react";
import { Input, Button, Form, Row, Col, Typography, message } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { changePassword } from "../../../../../endpoints";

interface Props extends FormComponentProps {}

export const tailFormItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 18, offset: 0 },
    lg: { span: 20, offset: 0 },
    xl: { span: 3, offset: 0 },
  },
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 8, offset: 0 },
    lg: { span: 18, offset: 0 },
    xl: { span: 12, offset: 1 },
  },
};

export const itemWithoutLabelLayout = {
  labelCol: {
    sm: { span: 24 },
    lg: { span: 12 },
    xl: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24, offset: 0 },
    xl: { span: 12, offset: 4 },
  },
};

const PasswordChangeSetting = (props: Props) => {
  const { getFieldValue, getFieldDecorator, getFieldsError, setFieldsValue } =
    props.form;

  const [hasResetFailed, setHasResetFailed] = useState(false);

  const hasErrors = (fieldsError) => {
    return Object.keys(fieldsError).some((field) => fieldsError[field]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!isSamePassword()) {
      setHasResetFailed(true);
      return;
    }
    props.form.validateFields((err, values) => {
      if (!err) {
        changePassword(values["input-password"]);
        message.success("Das Passwort wurde erfolgreich geändert.", 2);
        setFieldsValue({ "input-password": null });
        setFieldsValue({ "input-password-repeat": null });
        setHasResetFailed(false);
      }
    });
  };

  const isSamePassword = (): boolean => {
    const pw1 = getFieldValue("input-password");
    const pw2 = getFieldValue("input-password-repeat");
    return pw1 === pw2;
  };

  return (
    <Form onSubmit={handleSubmit} labelAlign="left" {...tailFormItemLayout}>
      <Row>
        <Col lg={12} sm={24}>
          <Form.Item label="Passwort" {...tailFormItemLayout}>
            {getFieldDecorator("input-password", {
              rules: [{ required: true, message: "Pflichtfeld" }],
            })(<Input.Password placeholder="Passwort ändern" />)}
          </Form.Item>
          <Form.Item label="Wiederholen" {...tailFormItemLayout}>
            {getFieldDecorator("input-password-repeat", {
              rules: [{ required: true, message: "Pflichtfeld" }],
            })(<Input.Password placeholder="Passwort ändern" />)}
          </Form.Item>
          <Form.Item {...itemWithoutLabelLayout}>
            <Button
              disabled={hasErrors(getFieldsError())}
              type="primary"
              htmlType="submit"
            >
              Ändern
            </Button>
          </Form.Item>
        </Col>
      </Row>
      {hasResetFailed ? (
        <Row style={{ marginTop: "5px" }}>
          <Col offset={2}>
            <Typography.Text type="danger">
              Passwörter müssen übereinstimmen
            </Typography.Text>
          </Col>
        </Row>
      ) : null}
    </Form>
  );
};

export default Form.create<Props>()(PasswordChangeSetting);
