import styled from 'styled-components';

interface MarginProps {
    top?: string;
    bottom?: string;
    left?: string;
    right?: string;
    all?: string
};



export const MarginComponent = styled.div<MarginProps>`
    margin: ${props => props.all || "0px"};
    margin-top: ${props => props.top || "0px"};
    margin-left: ${props => props.left || "0px"};
    margin-right: ${props => props.right || "0px"};
    margin-bottom: ${props => props.bottom || "0px"};    
`