import React from "react";
import { Row, Col, Layout, Input, Button } from "antd";
import PasswordChangeSetting from "./PasswordChangeSetting";

const UserSettings = props => {
  return (
    <Col style={{ margin: "20px" }}>
      <Row>
        <Col>
          <h3>Einstellungen</h3>
        </Col>
      </Row>
      <Row>
        <Col lg={24}>
          <PasswordChangeSetting />
        </Col>
      </Row>
    </Col>
  );
};

export default UserSettings;
