import * as React from "react";

interface GrundbuchkreisGrouped {
  municipalityCode: string;
  folderName: string;
}

interface Props {
  assuranceNumber: string;
  municipalityCode: string;
  municipality: string;
}

const sharepointBaseUrl =
  "https://extranet.gvasg.ch/raum/bsk/Intranet - Anlagedokumentationen";

const grundbuchkreisGrouped: GrundbuchkreisGrouped[] = [
  {
    municipalityCode: "02",
    folderName: "Grundbuchkreis 1-30"
  },
  {
    municipalityCode: "03",
    folderName: "Grundbuchkreis 31-60"
  },
  {
    municipalityCode: "04",
    folderName: "Grundbuchkreis 61-91"
  },
  {
    municipalityCode: "05",
    folderName: "Grundbuchkreis 97-99"
  }
];

const SharepointDocumentLink: React.FunctionComponent<Props> = ({
  assuranceNumber,
  municipalityCode,
  municipality
}) => {
  const buildSharepointDocumentUri = () => {
    const grundBuchKreisFiltered = grundbuchkreisGrouped.filter(
      x => x.municipalityCode === municipalityCode
    );

    if (!grundBuchKreisFiltered && grundBuchKreisFiltered.length < 1) {
      console.error(`Kein Grundbuchkreis gefunden für ${municipalityCode}`);
    }

    const code = parseInt(municipalityCode);
    let folderName = "";
    if (code > 0 && code <= 30) {
      folderName = "Grundbuchkreis 1-30";
    }
    else if (code > 30 && code <= 60) {
      folderName = "Grundbuchkreis 31-60";
    }
    else if (code > 60 && code <= 91) {
      folderName = "Grundbuchkreis 61-91";
    }
    else if (code >= 97 && code <= 99) {
      folderName = "Grundbuchkreis 97-99";
    }
    const grundBuchKreis = folderName;    

    const url = `${sharepointBaseUrl}/${grundBuchKreis}/${municipalityCode} ${municipality}/${assuranceNumber}/`;

    return url;
  };

  const invalidParameters =
    !municipality || !municipalityCode || !assuranceNumber;

  return invalidParameters ? null : (
    <a href={buildSharepointDocumentUri()} target="_blank">
      Sharepoint
    </a>
  );
};

export default SharepointDocumentLink;
