import * as React from "react";
import moment from "moment";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";

interface Props  {
  inspectionId: string;
  inspectionDate: Date;
}

const PreviousInspectionLink: React.FunctionComponent<Props> = ({
  inspectionId,
  inspectionDate
}) => {
  const forward = (inspectionId: string) => {
    window.open(`/kontrollen/${inspectionId}`);
    // history.push(`/kontrollen/${inspectionId}`);
    // window.location.reload();
  };

  return (
    <a onClick={() => forward(inspectionId)}>{moment(inspectionDate).format("DD.MM.YYYY")}</a>
  );
};

export default PreviousInspectionLink;
