import * as React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Layout } from "antd";
import "antd/dist/antd.css";

import Route from "./environment/routes";
import TopNavigation from "./components/shared/Header/TopNavigation";

import { MarginComponent } from "./components/shared/base-styles";

import { LocaleProvider } from "antd";
import de from "antd/es/locale-provider/de_DE";
import ErrorBoundary from "./components/shared/ErrorBoundary";
import TelemetryProvider from "./components/shared/telemetry-provider";
import { getAppInsights } from "./components/shared/telemetry-service";


const { Content } = Layout;

const App = () => {
  let appInsights = null;
  const instrumentationKey: string = process.env.INSIGHTS_INSTRUMENTATION_KEY || "";
  
  return (
    <Router>
      <TelemetryProvider instrumentationKey={instrumentationKey}
        after={() => { appInsights = getAppInsights() }}>
        <ErrorBoundary>
          <Layout>
            <TopNavigation />
            <Content style={{ backgroundColor: "white" }}>
              <LocaleProvider locale={de}>
                <MarginComponent all="10px">
                  <Route />
                </MarginComponent>
              </LocaleProvider>
            </Content>
          </Layout>
        </ErrorBoundary>
      </TelemetryProvider>
    </Router>
  );
}

export default App;
