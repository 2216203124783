import React from 'react';
import {Inspection, Property} from '../models';

interface inspectionContextModel{
    inspection: Inspection;
    property: Property;
    submitFormRef: (ref:any) => void;
    onChangeSystemInstalled: (element) => void,
    onChangeObjectIsInRenovation: (element) => void,
    updateInspectionHandler:(inspection:Inspection, property:Property, newStatus : number) =>void;
}

const inspectionContext = React.createContext<inspectionContextModel>({
    inspection: {} as Inspection,
    property: {} as Property,
    submitFormRef: (ref)=>{},
    onChangeSystemInstalled: (element) => {},
    onChangeObjectIsInRenovation: (element) => {},
    updateInspectionHandler: (inspection, property,newStatus : number) =>{}
});

export default inspectionContext;