import * as React from "react";
import { useState, useEffect } from "react";
import "./index.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { getCalendarByInspector } from "../../../endpoints";
import { Moment } from "moment";
import "moment/locale/de-ch";
import { Inspection, Property, CalendarItem } from "../../../models";
import { Event } from "./Event";
import { CalendarProps, NavigateAction, View } from "react-big-calendar";

let moment = require("moment");

const BigCalendar = require("react-big-calendar");
moment.locale("de-CH");
const localizer = BigCalendar.momentLocalizer(moment);
const DragAndDropCalendar = withDragAndDrop(BigCalendar.Calendar);

interface Props extends CalendarProps {
  currentInspection: Inspection;
  inspectorId: string;
  property: Property;
  changeDateCallback?: (newStart: Date, newEnd: Date) => Promise<void>;
}

const GvaCalendar = (props: Props) => {
  const [inspectorEvents, setEvents] = useState<Event[]>([]);
  const [currentViewStartDate, setCurrentViewStartDate] = useState<Date>(
    new Date()
  );

  useEffect(() => {
    const { startTime, endTime } = props.currentInspection;

    let start: Moment = moment();
    let end: Moment = moment();

    if (startTime && endTime) {
      start = moment(startTime);
      end = moment(endTime);
    } else {
      start = moment(currentViewStartDate);
      end = moment(currentViewStartDate);
    }

    const from = start.startOf("week").toDate();
    const to = end.endOf("week").toDate();

    loadEvents(from, to).then(events => {
      setEvents(events);
    });
  }, [props.currentInspection.startTime]);

  const onNavigate = (date, view, action) => {
    setCurrentViewStartDate(date);
  };

  const loadEvents = async (start: Date, end: Date): Promise<Event[]> => {
    const calendarItems: CalendarItem[] = await getCalendarByInspector(
      props.inspectorId,
      start,
      end
    );

    if (calendarItems) {
      return calendarItems.map(
        ({
          startDateTime,
          endDateTime,
          inspectionId,
          assuranceNumber,
          street
        }) => ({
          id: inspectionId,
          title: assuranceNumber + "\n" + street,
          start: moment.utc(startDateTime).toDate(),
          end: moment.utc(endDateTime).toDate(),
          isCurrent: props.currentInspection.id === inspectionId,
          isAllDay: false
        })
      );
    } else {
      return [];
    }
  };

  const eventStyleGetter = (
    event: Event,
    start: any,
    end: any,
    isSelected: boolean
  ) => {
    let style = {
      backgroundColor: ""
    };

    if (event.isCurrent) {
      style.backgroundColor = "#196F3D";
    }

    return {
      style: style
    };
  };

  const handleSelect = ({ start, end }) => {
    const hasEvent = inspectorEvents.filter(x => x.isCurrent)[0];
    if (!hasEvent) {
      const title = createEventText();
      const newEvents: Event[] = [
        ...inspectorEvents,
        {
          end: end,
          id: props.currentInspection.id,
          start: start,
          isCurrent: true,
          isAllDay: false,
          title: title
        }
      ];
      saveNewDate(newEvents, start, end);
      return;
    }

    hasEvent.start = start;
    hasEvent.end = end;

    const nextEvents = inspectorEvents.map(existingEvent => {
      return existingEvent.id === hasEvent.id
        ? { ...existingEvent, start, end }
        : existingEvent;
    });

    saveNewDate(nextEvents, start, end);
  };

  const moveEvent = ({ event, start, end }) => {
    const idx = inspectorEvents.indexOf(event);
    const updatedEvent = { ...event, start, end };
    const nextEvents = [...inspectorEvents];
    nextEvents.splice(idx, 1, updatedEvent);
    saveNewDate(nextEvents, start, end);
  };

  const resizeEvent = ({ event, start, end }) => {
    const nextEvents = inspectorEvents.map(existingEvent => {
      return existingEvent.id === event.id
        ? { ...existingEvent, start, end }
        : existingEvent;
    });
    saveNewDate(nextEvents, start, end);
  };

  const onRangeChange = (dateRanges: Date[]) => {
    const startDate = moment(dateRanges[0]);
    const endDate = moment(dateRanges[dateRanges.length - 1]).add(1, "days");

    loadEvents(startDate, endDate).then(events => {
      setEvents(events);
    });
  };

  const saveNewDate = (events: Event[], start, end) => {
    setEvents(events);

    props.changeDateCallback(start, end);
  };

  const createEventText = (): string => {
    if (!props.property) return "Kein Gebäude hinzugefügt";

    const { street, streetNumber, assuranceNumber } = props.property;
    let eventText = `${assuranceNumber}\n${street} ${streetNumber}`;

    return eventText;
  };

  return (
    <DragAndDropCalendar
      draggableAccessor={event => event.isCurrent === true}
      eventPropGetter={eventStyleGetter}
      onEventDrop={moveEvent}
      onEventResize={resizeEvent}
      onSelectSlot={handleSelect}
      onRangeChange={onRangeChange}
      onNavigate={onNavigate}
      events={inspectorEvents}
      selectable
      resizable
      defaultDate={
        props.currentInspection.startTime
          ? props.currentInspection.startTime
          : new Date()
      }
      defaultView="work_week"
      views={["work_week", "week"]}
      min={new Date(2017, 10, 0, 7, 0, 0)}
      max={new Date(2030, 10, 0, 18, 0, 0)}
      localizer={localizer}
      messages={{
        today: "Heute",
        previous: "Zurück",
        next: "Nächste",
        day: "Tag",
        week: "Woche",
        work_week: "Arbeitswoche"
      }}
    />
  );
};

export default GvaCalendar;
