import React, { useEffect, useState } from "react";
import { Input, Form, Row, Select, AutoComplete } from "antd";
import { Property, Inspection } from "../../../../../../models";
import TextArea from "antd/lib/input/TextArea";
import { Municipalities } from "../../../../../../models/municipalities";
import { getSystemInstallers } from "../../../../../../endpoints";
import { authenticationService } from "../../../../../../authentication/authenticationService";

const { Option } = Select;

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 14 },
};

interface AdministratorProps {
  property?: Property;
  inspection?: Inspection;
  form: any;
}

const PropertyDetailFormData = (props: AdministratorProps) => {
  const { getFieldDecorator } = props.form;
  const {
    streetNumber = "",
    street = "",
    assuranceNumber = "",
    municipality = "",
    usageKind = "",
  } = props.property || {};

  const {
    affectedBuildingParts = "",
    subsidyNumber = "",
    permitNumber = "",
    systemInstaller = "",
  } = props.inspection;

  const [systemInstallers, setSystemInstallers] = useState<string[]>([]);
  const [systemInstallersSearch, setSystemInstallersSearch] = useState<
    string[]
  >([]);

  const loadData = async () => {
    const inspectorId = authenticationService.currentUserValue.id;
    const systemInstallers = await getSystemInstallers(inspectorId);
    setSystemInstallers(systemInstallers);
    setSystemInstallersSearch(systemInstallers);
    console.log("data loaded");
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <Row>
        <h3>Gebäudedaten</h3>
      </Row>
      <Row style={{ marginTop: "10px" }}>
        <Form.Item label="Versicherungsnummer" {...formItemLayout}>
          {getFieldDecorator("property-data-assuranceNumber", {
            initialValue: assuranceNumber,
            rules: [
              {
                required: true,
                message: "Bitte geben Sie eine Versicherungsnummer ein.",
              },
              {
                max: 45,
                message: "Der Wert darf nicht länger als 45 Zeichen sein.",
              },

              //, { validator: (rule, value, callback) => { callback(); }, message: "Falsches Format" }
            ],
          })(<Input placeholder="Vers. Nr" />)}
        </Form.Item>
        <Form.Item label="Strasse" {...formItemLayout}>
          {getFieldDecorator("property-data-street", {
            initialValue: street,
            rules: [
              { required: true, message: "Bitte geben Sie eine Strasse ein." },
              {
                max: 45,
                message: "Der Wert darf nicht länger als 45 Zeichen sein.",
              },
            ],
          })(<Input placeholder="Strasse" />)}
        </Form.Item>

        <Form.Item label="Hausnummer" {...formItemLayout}>
          {getFieldDecorator("property-data-streetNr", {
            initialValue: streetNumber,
            rules: [
              {
                max: 9,
                message: "Der Wert darf nicht länger als 9 Zeichen sein.",
              },
            ],
          })(<Input placeholder="Hausnummer" />)}
        </Form.Item>
        <Form.Item label="Grundbuchkreis" {...formItemLayout}>
          {getFieldDecorator("property-data-municipality", {
            initialValue: municipality == "" ? undefined : municipality,
            rules: [
              {
                required: true,
                message: "Bitte geben Sie einen Grundbuchkreis ein.",
              },
            ],
          })(
            <Select
              showSearch
              placeholder="Grundbuchkreis wählen"
              filterOption={(input: string, option) => {
                input = input.toLowerCase();
                return (
                  option.props.children
                    .toString()
                    .toLowerCase()
                    .startsWith(input) || option.key == input
                );
              }}
            >
              {Municipalities.sort().map((mun) => (
                <Option key={mun.code}>{mun.text}</Option>
              ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item label="Nutzungsart" {...formItemLayout}>
          {getFieldDecorator("property-data-usageKind", {
            initialValue: usageKind,
            rules: [
              {
                required: true,
                message: "Bitte geben Sie eine Nutzungsart ein.",
              },
              {
                max: 255,
                message: "Der Wert darf nicht länger als 255 Zeichen sein.",
              },
            ],
          })(<Input placeholder="Nutzungsart" />)}
        </Form.Item>

        <Form.Item label="Gebäudeteile" {...formItemLayout}>
          {getFieldDecorator("property-data-affectedBuildingParts", {
            initialValue: affectedBuildingParts,
            rules: [
              {
                max: 500,
                message: "Der Wert darf nicht länger als 500 Zeichen sein.",
              },
            ],
          })(<TextArea placeholder="Betroffene Gebäudeteile" />)}
        </Form.Item>

        <Form.Item label="Subventions-Nr" {...formItemLayout}>
          {getFieldDecorator("property-data-subsidyNumber", {
            initialValue: subsidyNumber,
            rules: [
              {
                max: 45,
                message: "Der Wert darf nicht länger als 45 Zeichen sein.",
              },
            ],
          })(<TextArea placeholder="Subventionsnummer" />)}
        </Form.Item>

        <Form.Item label="Bewilligungs-Nr" {...formItemLayout}>
          {getFieldDecorator("property-data-permitNumber", {
            initialValue: permitNumber,
            rules: [
              {
                max: 45,
                message: "Der Wert darf nicht länger als 45 Zeichen sein.",
              },
            ],
          })(<TextArea placeholder="Bewilligungsnummer" />)}
        </Form.Item>

        <Form.Item label="Erstellfirma" {...formItemLayout}>
          {getFieldDecorator("property-data-systemInstaller", {
            initialValue: systemInstaller,
            rules: [
              {
                max: 250,
                message: "Der Wert darf nicht länger als 250 Zeichen sein.",
              },
            ],
          })(
            <AutoComplete
              placeholder={"Erstellfirma der BSA"}
              dataSource={systemInstallersSearch}
              onSearch={(searchTerm) => {
                const maxResults = 100;
                searchTerm = searchTerm.toLowerCase();
                const results = systemInstallers
                  .filter((installer) =>
                    installer.toLowerCase().startsWith(searchTerm)
                  )
                  .slice(0, maxResults);
                setSystemInstallersSearch(results);
              }}
            >
              <TextArea style={{ height: 80 }} />
            </AutoComplete>
          )}
        </Form.Item>
      </Row>
    </>
  );
};

export default PropertyDetailFormData;
