import * as React from "react";
import { Row, Col, Button } from "antd";
import { useEffect } from "react";
import {
  getCancelledInspections,
  getPropertyById,
  putAcceptCancellation
} from "../../../../../endpoints";
import { Property } from "../../../../../models";

interface Props {
  inspectorId: string;
}

const CanceledInspections = (props: Props) => {
  const [propertiesState, setProperties] = React.useState<Property[]>([]);

  useEffect(() => {
    getCancelledInspections(props.inspectorId).then(i => {
      if (i) {
        let promises = i.map(inspection => {
          return getPropertyById(inspection.propertyId).then(property => {
            return property;
          });
        });

        Promise.all(promises).then(result => setProperties(result));
      }
    });
  }, [props.inspectorId]);

  const propertyColumns = propertiesState.map(p => {
    return (
      <Row key={p.assuranceNumber}>
   
        <Col span={1} style={{width:80}}>
          {p.assuranceNumber}
        </Col>
        <Col span={1} style={{minWidth:230}} >
          {p.usageKind}
        </Col>
        <Col span={1} style={{minWidth:200}}  >
          {p.street}
        </Col>
        <Col span={1}  >
          {p.municipality}
        </Col>
      </Row>
    );
  });

  const acceptCancellations = async () => {
    await putAcceptCancellation(props.inspectorId);
    window.location.reload();
  };

  return (
    propertiesState.length > 0 && (
      <Row style={{ padding: "20px", border: "2px solid orange" }}>
        <Col>
          <Row>
            <Col>
              <p style={{ fontWeight: "bold" }}>
                Bitte nehmen Sie zur Kenntnis, dass folgende Aufträge
                zurückgezogen wurden:
              </p>
            </Col>
          </Row>
          {propertyColumns}
          <Row style={{ marginTop: "20px" }}>
            <Col offset={0}>
              <Button onClick={acceptCancellations} type="primary">
                Zur Kenntnis nehmen
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    )
  );
};

export default CanceledInspections;
