import { Col, Form, Row } from "antd";
import { FormComponentProps } from "antd/lib/form/Form";
import React from "react";
import { isNullOrUndefined } from "util";
import UserContext from "../../../../../../../context/user-context";
import InspectionContext from "../../../../../../../context/inspection-context";
import { Inspection, Property } from "../../../../../../../models";
import GvaCalendar from "../../../../../../shared/Calendar";
import InspectionPreparationForm from "./InspectionPreparationForm";
import moment from "moment";
import { momentLocalizer } from "react-big-calendar";

moment.locale("de-CH");

interface Props extends FormComponentProps {
  inspection: Inspection;
  property: Property;
  updateInspection: (inspection: Inspection, property?: Property) => void;
  stepFormRef?: any;
}

const PreparationStep = (props: Props) => {
  const userContext = React.useContext(UserContext);
  const { startTime, endTime } = props.inspection;
  const [dateRange, setDateRange] = React.useState([
    moment(startTime),
    moment(endTime)
  ]);

  const updateDate = async (newStart: Date, newEnd: Date): Promise<void> => {
    const inspectionUpdate: Inspection = {
      startTime: newStart,
      endTime: newEnd,
      inspectorId: userContext.user.id
    };
    setDateRange([moment(newStart), moment(newEnd)]);

    props.updateInspection(inspectionUpdate);
  };

  const removeDate = () => {
    const inspectionUpdate: Inspection = {
      startTime: null,
      endTime: null,
      inspectorId: userContext.user.id
    };

    setDateRange([moment(null), moment(null)]);
    props.updateInspection(inspectionUpdate);
  };

  const calendar =
    !isNullOrUndefined(props.inspection.id) &&
    !isNullOrUndefined(userContext.user.id) ? (
      <GvaCalendar
        inspectorId={userContext.user.id}
        currentInspection={props.inspection}
        changeDateCallback={updateDate}
        localizer={momentLocalizer(moment)}
        property={props.property}
      />
    ) : null;

  return (
    <Row>
      <Col lg={12} sm={24} style={{ marginBottom: "5px" }}>
        <InspectionContext.Consumer>
          {() => (
            <InspectionPreparationForm
              removeInspectionDate={removeDate}
              ref={props.stepFormRef}
              dateRange={dateRange}
              inspection={props.inspection}
              property={props.property}
              updateInspection={props.updateInspection}
            />
          )}
        </InspectionContext.Consumer>
      </Col>
      <Col lg={12} sm={24}>
        {calendar}
      </Col>
    </Row>
  );
};

export default Form.create<Props>()(PreparationStep);